<template>
  <Navbar />
  <div :class="'p-4 mt-20  ' + (isRtl ? 'sm:mr-64' : 'sm:ml-64')">
    <div class="lg:flex lg:mx-6">
      <!-- Left Side -->
      <div class="w-full lg:w-6/12 lg:mx-2 lg:mt-4">
        <!-- Profile Card -->
        <div
          class="bg-white p-8 border-t-4 rounded-xl border-primaryColor shadow-md"
        >
          <h1 class="my_font text-gray-600 font-bold text-2xl leading-8 my-1">
            {{ user.u_name }}
          </h1>

          <ul class="text-gray-600 py-2 px-1 mt-3 divide-y rounded">
            <li class="my_font flex items-center py-4">
              <span class="my_font">{{ $t("account_status") }}</span>
              <div class="mx-auto"></div>
              <span
                class="my_font bg-green-500 py-2 px-4 rounded-lg text-white text-base"
                >{{ user.u_state == 1 ? $t("active") : $t("block") }}</span
              >
            </li>
            <li class="my_font flex items-center py-4">
              <span class="my_font">{{ $t("account_created_at") }}</span>
              <div class="mx-auto"></div>
              <span class="my_font">{{
                user.created_at.toString().substring(0, 10)
              }}</span>
            </li>
            <li class="my_font flex items-center py-4">
              <span class="my_font">{{ $t("account_discount") }}</span>
              <div class="mx-auto my_font"></div>
              <span
                class="my_font bg-primaryColor py-2 px-4 rounded-lg text-white text-sm"
                >{{ getMyAccountDiscount(user.u_discount) }} </span
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="lg:mx-3"></div>
      <!-- Right Side -->
      <div class="w-full lg:w-6/12 lg:mx-2 mt-8 md:mt-4">
        <!-- Profile tab -->
        <!-- About Section -->
        <div
          class="bg-white p-6 shadow-md rounded-xl border-t-4 border-primaryColor"
        >
          <div
            class="block mb-2 items-center space-y-2 font-semibold text-gray-900 leading-8"
          >
            <span class="my_font font-semibold text-xl">{{
              $t("more_info")
            }}</span>
          </div>
          <ul class="text-gray-600 py-2 px-1 mt-3 divide-y rounded">
            <li class="my_font flex items-center py-4">
              <span class="my_font">{{ $t("phone_number") }}</span>
              <div class="my_font mx-auto"></div>
              <span class="my_font">{{ user.u_phone }}</span>
            </li>
            <li class="my_font flex items-center py-4">
              <span class="my_font">{{ $t("second_phone_number") }}</span>
              <div class="mx-auto"></div>
              <span class="my_font">{{ user.u_phone_2 }}</span>
            </li>
            <li class="flex items-center py-4">
              <span class="my_font">{{ $t("instagram") }}</span>
              <div class="mx-auto"></div>
              <span class="my_font">{{ user.u_instagram }}</span>
            </li>
            <li class="flex items-center py-4">
              <span class="my_font">{{ $t("city") }}</span>
              <div class="mx-auto"></div>
              <span class="my_font">{{ $t(user.u_city) }}</span>
            </li>
            <li class="flex items-center py-4">
              <span class="my_font">{{ $t("address") }}</span>
              <div class="mx-auto"></div>
              <span class="my_font my_font">{{ user.u_address }}</span>
            </li>
          </ul>
          <a
            href="/profile/settings"
            class="my_font block w-full text-white bg-gradient-to-br from-primaryColorDark to-blue-300 text-center text-base rounded-lg focus:outline-none hover:shadow-xs p-3 my-4"
            >{{ $t("update_info") }}</a
          >
        </div>
        <!-- End of about section -->
        <!-- End of profile tab -->
      </div>
    </div>
    <!--Pending Orders-->
    <!-- <div class="flex flex-col w-full mt-16 mb-10">
      <span class="text-center items-center font-semibold text-xl text-gray-600">Pending
          Orders</span>
      <hr class="mt-2 mb-4 h-px w-9/10 mx-auto">
      <div
          class="w-98per flex items-center bg-purple-50 h-20 mx-auto rounded-xl shadow-md justify-between px-8">
              
              <div class="px-4 py-1.2 bg-gradient-to-br from-primaryColor to-purple-400 rounded-full text-center">
                  <p class="text-white text-4 my-auto">#2356</p>
              </div>
              <p class="text-gray-600 font-semibold">23 Apr 2023</p>
              <p class="text-gray-600 font-semibold">23$</p>
          <div class="flex space-x-6">
              
              <div class="flex px-4 py-1.2 bg-green-200 rounded-full">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                      class="w-3 h-3 text-green-600 my-auto" viewBox="0 0 507.506 507.506">
                      <path
                          d="M163.865 436.934a54.228 54.228 0 0 1-38.4-15.915L9.369 304.966c-12.492-12.496-12.492-32.752 0-45.248 12.496-12.492 32.752-12.492 45.248 0l109.248 109.248L452.889 79.942c12.496-12.492 32.752-12.492 45.248 0 12.492 12.496 12.492 32.752 0 45.248L202.265 421.019a54.228 54.228 0 0 1-38.4 15.915z" />
                  </svg>
                  <div class="w-1"></div>
                  <p class="text-green-600 text-xs font-semibold my-auto">Accept</p>
              </div>

              <div class="p-2 bg-primaryColorLight rounded-blur">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-4 h-4 text-primaryColorDark"
                      viewBox="0 0 24 24">
                      <path
                          d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.908 4.908 0 0 0 0 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.908 4.908 0 0 0 0-5.162Zm-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.918 2.918 0 0 1 0-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.918 2.918 0 0 1 0 3.068Z" />
                      <path
                          d="M12 7a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5Zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3Z" />
                  </svg>
              </div>

          </div>

      </div>
  </div> -->

    <!--Pending Orders-->
    <div
      class="flex flex-col w-full mt-16 mb-5"
      v-if="latestOrders.length != 0 && isLoadingOrders == false"
    >
      <span
        class="my_font text-center items-center font-semibold text-xl text-gray-700"
        >{{ $t("latest_orders") }}</span
      >
      <hr class="mt-2 mb-4 h-px w-9/10 mx-auto" />
    </div>

    <div
      class="w-11/12 mx-auto grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8"
    >
      <!--first order-->
      <div
        class="flex flex-col p-5 bg-white border-t-4 border-primaryColor rounded-xl shadow-md"
        data-aos="fade-up"
        data-aos-duration="1000"
        v-for="(order, index) in latestOrders"
        :key="index"
      >
        <h1 class="text-gray-700 font-bold text-2xl leading-8 my-1">
          #{{ order.o_id }}
        </h1>
        <ul class="text-gray-700 py-2 px-1 mt-3 divide-y rounded">
          <li class="flex items-center py-4">
            <span class="my_font">{{ $t("date") }}</span>
            <div class="mx-auto"></div>
            <span class="my_font">{{
              order.created_at.toString().substring(0, 10)
            }}</span>
          </li>
          <li class="flex items-center py-4">
            <span class="my_font">{{ $t("total_price") }}</span>
            <div class="mx-auto"></div>
            <span class="my_font">{{
              formatNumber(
                order.o_price * (1 -parseFloat( order.o_discount)) + parseFloat(order.o_delivery_fees)
              )
            }}</span>
          </li>
          <li class="flex items-center py-4">
            <div class="my_font">{{ $t("order_status") }}</div>
            <div class="mx-auto"></div>
            <div
              :class="
                'flex justify-center py-2 px-4 rounded-lg text-white text-sm ' +
                (order.o_status == 'pending'
                  ? 'bg-gradient-to-br from-gray-100 to-gray-200 text-gray-600'
                  : order.o_status == 'accepted'
                  ? 'bg-gradient-to-br from-green-100 to-green-200 text-green-600'
                  : order.o_status == 'canceled'
                  ? 'bg-gradient-to-br from-orange-100 to-orange-200 text-orange-600'
                  : 'bg-gradient-to-br from-red-100 to-red-200 text-red-600')
              "
            >
              <font-awesome-icon
                :class="
                  'w-3 h-3 my-auto mx-1 ' +
                  (order.o_status == 'pending'
                    ? 'text-gray-600'
                    : order.o_status == 'accepted'
                    ? 'text-green-600'
                    : order.o_status == 'canceled'
                    ? 'text-orange-600'
                    : 'text-red-600')
                "
                :icon="[
                  'fas',
                  order.o_status == 'pending'
                    ? 'clock'
                    : order.o_status == 'accepted'
                    ? 'check'
                    : order.o_status == 'canceled'
                    ? 'rotate-left'
                    : 'xmark',
                ]"
                size="sm"
              />
              <span
                :class="
                  'my_font text-3.5 font-medium my-auto ' +
                  (order.o_status == 'pending'
                    ? 'text-gray-600'
                    : order.o_status == 'accepted'
                    ? 'text-green-600'
                    : order.o_status == 'canceled'
                    ? 'text-orange-600'
                    : 'text-red-600')
                "
                >{{ $t(order.o_status) }}</span
              >
            </div>
          </li>
          <li class="py-4">
            <a
              class="flex justify-center bg-gradient-to-br from-primaryColor to-blue-400 py-2 px-4 w-11/12 mx-auto rounded-lg text-white text-sm"
              :href="'/profile/orders/' + order.o_id"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="w-4 h-4 text-white my-auto"
                viewBox="0 0 24 24"
              >
                <path
                  d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.908 4.908 0 0 0 0 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.908 4.908 0 0 0 0-5.162Zm-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.918 2.918 0 0 1 0-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.918 2.918 0 0 1 0 3.068Z"
                />
                <path
                  d="M12 7a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5Zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3Z"
                />
              </svg>
              <div class="w-3"></div>
              <span class="my_font text-3.5 font-medium my-auto">{{
                $t("view_order")
              }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "./components/nav-bar.vue";

import "swiper/swiper-bundle.min.css";
import { SERVER_URL } from "../../config.js";
import axios from "axios";
//import axios from "axios";
export default {
  name: "App",

  components: {
    Navbar,
  },
  data() {
    return {
      url: SERVER_URL,
      item: {},

      isLoading: true,
      isLoadingOrders: true,
      isLoginShow: false,
      user: {},
      token: "",
      latestOrders: [],
    };
  },
  computed: {
    isRtl() {
      return (
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == "ku" ||
        //if null
        localStorage.getItem("lang") == null ||
        //if empty
        localStorage.getItem("lang") == ""
      );
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.token = localStorage.getItem("token");
    this.getLatestOrders();
  },
  mounted() {},
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.href = "/signin";
    },
    getMyAccountDiscount() {
      //0.05 => 5% convert to %05
      
      return this.user.u_discount * 100 + "%";
    },
    getLatestOrders() {
      axios
        .post(SERVER_URL + "api/order/latest")
        .then((response) => {
          // this.items = response.data;
          this.latestOrders = response.data;
          console.log(this.latestOrders);
          this.isLoadingOrders = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoadingOrders = false;
        });
    },
  },
};
</script>

<style></style>
