<template>
  <div :class="{ 'dir-rtl': isRtl }">
    <Loading v-if="loading" />
    <router-view v-else />
  </div>
</template>

<script>
import AOS from "aos";
import Loading from "./loading-page.vue";

export default {
  name: "App",
  components: {
    Loading,
  },
  data() {
    return {
      isLoad: false,
    };
  },
  computed: {
    isRtl() {
      return (
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == "ku" ||
        //if null
        localStorage.getItem("lang") == null ||
        //if empty
        localStorage.getItem("lang") == ""
      );
    },
    //function to check if loading or not
    loading() {
      console.log(this.$store.state.loading);
      return this.isLoad; // Access the loading state from the Vuex store
    },
  },
  created() {
    //this.isLoad = true;
  },
  mounted() {
    AOS.init();

    /*setTimeout(() => {
      this.isLoad = false;
    }, 2000);*/
    //on page loaded
  },
};
</script>

<style>
.dir-rtl {
  direction: rtl !important;
}
</style>
