<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
    <meta name="description" :content="description" />
  </metainfo>
  <!--<teleport to="head">
    <title>{{ offer.o_title }}</title>
    <meta property="og:description" :content="offer.o_desc" />
    <meta property="og:site_name" content="KC STORE" />
    <meta
      property="og:image"
      :content="url + 'kcstore.online/public/storage/' + offer.o_image"
    />
  </teleport>-->
  <Navbar />

  <main
    class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white mt-4 md:mt-16"
    v-if="!isLoading"
  >
    <div class="flex px-4 mx-auto max-w-screen-xl">
      <article class="mx-auto w-full max-w-3xl">
        <img
          :src="
            url +
            'kcstore.online/public/storage/' +
            (lang == 'en'
              ? offer.o_image_en
              : lang == 'ar'
              ? offer.o_image_ar
              : offer.o_image)
          "
          alt=""
          class="w-10/12 mx-2 my-1"
        />
        <header class="my-4 lg:mb-6 not-format">
          <h1
            class="my_font text-2xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl"
          >
            {{
              lang == "en"
                ? offer.o_title_en
                : lang == "ar"
                ? offer.o_title_ar
                : offer.o_title
            }}
          </h1>
        </header>

        <p class="my_font whitespace-pre-wrap text-5 text-gray-700">
          {{
            lang == "en"
              ? offer.o_desc_en
              : lang == "ar"
              ? offer.o_desc_ar
              : offer.o_desc
          }}
        </p>
      </article>
    </div>
  </main>

  <aside aria-label="Related articles" class="py-8 lg:py-24 bg-gray-50">
    <div class="px-4 mx-auto max-w-screen-xl">
      <h2
        class="my_font mb-8 text-2xl font-bold tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-primaryColor to-primaryColorDark"
      >
        {{ $t("other_offers") }}
      </h2>
      <div
        class="grid gap-12 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
      >
        <article
          class="mx-auto max-w-xs"
          v-for="(offer, index) in relatedOffers"
          :key="index"
        >
          <a>
            <img
              :src="
                url +
                'kcstore.online/public/storage/' +
                (lang == 'en'
                  ? offer.o_image_en
                  : lang == 'ar'
                  ? offer.o_image_ar
                  : offer.o_image)
              "
              class="w-full object-cover"
              alt="Image 1"
            />
          </a>
          <h2
            class="my_font mb-2 text-xl font-bold leading-tight text-gray-900 my-1"
          >
            {{
              lang == "en"
                ? offer.o_title_en
                : lang == "ar"
                ? offer.o_title_ar
                : offer.o_title
            }}
          </h2>
          <p
            class="my_font mb-4 font-light text-gray-500 overflow-hidden line-clamp-3"
          >
            {{
              lang == "en"
                ? offer.o_desc_en
                : lang == "ar"
                ? offer.o_desc_ar
                : offer.o_desc
            }}
          </p>
          <a class="inline-block" :href="'/offers/' + offer.o_id">
            <button
              class="my_font flex select-none items-center gap-2 rounded-lg py-2 px-3 text-center text-sm align-middle uppercase text-white bg-gradient-to-br from-blue-400 to-primaryColorDark"
              type="button"
            >
              {{ $t("offer.seeMore") }}
              <!--if rtl add flip-horizontal to svg class-->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                aria-hidden="true"
                :class="'h-4 w-4 ' + (isRtl ? 'transform rotate-180' : '')"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                ></path>
              </svg>
            </button>
          </a>
        </article>
      </div>
    </div>
  </aside>

  <Footer />
</template>

<script>
import Navbar from "../components/nav-bar.vue";
import Footer from "../components/footer-app.vue";
import { SERVER_URL } from "../../config.js";
import axios from "axios";
import { useMeta } from "vue-meta";

export default {
  name: "App",

  setup() {
    useMeta({
      title: this.offer.o_title,
      description: this.offer.o_desc,
      //image
    });
  },
  components: {
    Navbar,
    Footer,
  },
  computed: {
    isRtl() {
      return (
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == "ku" ||
        //if null
        localStorage.getItem("lang") == null ||
        //if empty
        localStorage.getItem("lang") == ""
      );
    },
  },
  data() {
    return {
      url: SERVER_URL,
      offer: {},
      relatedOffers: [],
      isLoading: true,
      isLoadingRelated: true,
      lang: localStorage.getItem("lang"),
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getOffer();
  },
  mounted() {},
  methods: {
    getOffer() {
      axios
        .post(SERVER_URL + "api/offer/show/" + this.$route.params.id, {
          lang: this.lang,
        })
        .then((response) => {
          // this.items = response.data;
          this.offer = response.data;

          //check if the offer is empty
          if (Object.keys(this.offer).length === 0) {
            window.location.href = "/offers";
          } else {
            this.isLoading = false;
          }
          this.getRelatedOffers();
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.getRelatedOffers();
        });
    },
    getRelatedOffers() {
      axios
        .post(SERVER_URL + "api/offer/get-related/" + this.$route.params.id, {
          lang: this.lang,
        })
        .then((response) => {
          // this.items = response.data;
          this.relatedOffers = response.data;
          this.isLoadingRelated = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoadingRelated = false;
        });
    },
  },
};
</script>
