<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
    <meta name="description" :content="description" />
  </metainfo>

  <Navbar />

  <section
    class="bg-[url('/assets/image/background.jpg')] md:bg-center bg-cover bg-no-repeat"
  >
    <div
      class="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0"
    >
      <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1
            class="my_font text-2xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl"
          >
            {{ $t("sign_in") }}
          </h1>
          <form class="space-y-4 md:space-y-6" @submit="loginFunc">
            <div>
              <label
                for="tel"
                class="my_font text-base block mb-2 font-medium text-gray-900"
              >
                {{ $t("phone_number") }}</label
              >
              <input
                id="phone"
                type="text"
                autocomplete="tel"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
                required
                v-model="phone"
              />

              <div class="my_font cursor-pointer text-sm text-red-500">
                {{ $t(error_phone) }}
              </div>
            </div>
            <div>
              <label
                for="password"
                class="my_font text-base block mb-2 font-medium text-gray-900"
                >{{ $t("password") }}</label
              >
              <input
                type="password"
                name="password"
                id="password"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-base rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
                required
                autocomplete="current-password"
                v-model="password"
              />
              <div class="my_font cursor-pointer text-sm text-red-500">
                {{ $t(error_password) }}
              </div>
            </div>
            <div
              class="flex items-center justify-between"
              @click="forgetDialog = true"
            >
              <a
                class="my_font cursor-pointer text-base font-medium text-primaryColorDark hover:underline"
                >{{ $t("forget_password") }}</a
              >
            </div>
            <div class="my_font cursor-pointer text-sm text-red-500">
              {{ $t(phone_or_password_wrong) }}
            </div>
            <burron
              class="my_font w-full text-white bg-primaryColor hover:bg-primaryColorDark focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg text-lg px-5 py-2.5 text-center"
              @click="loginFunc"
              type="button"
            >
              <span
                class="my_font text-white text-lg text-center"
                v-if="!loadingBtn"
              >
                {{ $t("sign_in") }}</span
              >

              <div role="status" v-else>
                <svg
                  aria-hidden="true"
                  class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-primaryColor"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </burron>
            <p class="my_font text-base font-light text-gray-600">
              {{ $t("dont_have_an_account_yet") }}
              <router-link
                to="/signup"
                class="my_font text-base text-primaryColorDark hover:underline"
              >
                {{ $t("sign_up") }}</router-link
              >
            </p>
          </form>

          <!-- forget modal-->
          <!-- Main modal -->
          <div
            id="forgetModal"
            v-show="forgetDialog"
            tabindex="-1"
            aria-hidden="true"
            class="fixed top-0 left-0 right-0 z-50 flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
          >
            <div class="relative w-full max-w-2xl max-h-full">
              <!-- Modal content -->
              <div class="relative bg-white rounded-lg shadow">
                <!-- Modal header -->
                <div
                  class="flex items-start justify-between p-4 border-b rounded-t"
                >
                  <h3 class="my_font text-xl font-semibold text-gray-900">
                    {{ $t("forget_password") }}
                  </h3>
                  <button
                    type="button"
                    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-hide="forgetModal"
                    @click="forgetDialog = false"
                  >
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                <!-- Modal body -->
                <div class="p-4 space-y-6">
                  <p class="my_font text-lg leading-relaxed text-gray-600">
                    {{ $t("forget_password_text") }}
                  </p>
                </div>
                <!-- Modal footer -->
                <div
                  class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b"
                >
                  <a
                    href="https://www.instagram.com/korean_c_store_/"
                    target="_blank"
                    class="mx-2 my_font text-white bg-primaryColor hover:bg-primaryColorDark focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center"
                  >
                    {{ $t("instagram") }}
                  </a>
                  <button
                    data-modal-hide="forgetModal"
                    type="button"
                    class="my_font text-white bg-slate-600 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center"
                    @click="forgetDialog = false"
                  >
                    {{ $t("cancel") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</template>

<script>
import Navbar from "../components/nav-bar.vue";
import Footer from "../components/footer-app.vue";
import { SERVER_URL } from "../../config.js";
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useMeta } from "vue-meta";

export default {
  name: "App",

  setup() {
    useMeta({
      title: "چوونەژوورەوە",
      description:
        "پێستت بەشێکە لە تەندروستیت، تەندورستی پێستت بە ئێمە بسپێرە، بەهەبوونی زیاتر لە 2 ساڵ کارکردن لە بوارەکەدا و دابینکردنی بەناوبانگترین و چاکترین بەرهەمەکان لە وڵاتانی کۆریا و یابان و ولاتانی ئەوروپاوە، دەتوانین یارمەتیت بدەین بۆ بەدەستهێنانی پێستی دڵخواز و تەندروستی خۆت",
      //image
    });
  },
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      url: SERVER_URL,
      loadingBtn: false,
      phone: "",
      password: "",
      error_phone: "",
      error_password: "",
      forgetDialog: false,
      phone_or_password_wrong: "",
    };
  },
  mounted() {},
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    loginFunc() {
      //validate  phone and password
      if (this.phone == "") {
        this.error_phone = "please_enter_your_phone_number";
        return;
      }
      this.error_phone = "";
      if (this.password == "") {
        this.error_password = "please_enter_your_password";
        return;
      }
      this.error_password = "";
      this.loadingBtn = true;
      axios
        .post(SERVER_URL + "api/user/signin", {
          u_phone: this.phone.toString(),
          password: this.password,
        })
        .then((response) => {
          // this.items = response.data;

          if (response.data.success == true) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            window.location.href = "/profile";
          } else {
            // ToastOptions
          }
          this.loadingBtn = false;
        })
        .catch((error) => {
          console.log(error);
          toast(this.$t("phone_or_password_wrong"), {
            closeButton: true,
            position: "bottom-left",
            contentColor: "#000",
            content: error.response.data.message,
            type: "error",
          });
          this.phone_or_password_wrong = "phone_or_password_wrong";
          this.loadingBtn = false;
        });
    },
  },
};
</script>
<style scoped>
text-red1 {
  color: red !important;
}
</style>
