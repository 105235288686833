// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

//import { onBackgroundMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAQEPJAeU_yULepZkXSRUsvS2hHUT4jTg8",
  authDomain: "kcstore-c16d2.firebaseapp.com",
  projectId: "kcstore-c16d2",
  storageBucket: "kcstore-c16d2.appspot.com",
  messagingSenderId: "559679077362",
  appId: "1:559679077362:web:91d63cc220a35d4bb8c96b",
  measurementId: "G-P3XKZ8LW31",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

getAnalytics(app);
