<template>
  <Navbar />
  <div :class="'p-4 mt-20  ' + (isRtl ? 'sm:mr-64' : 'sm:ml-64')">
    <div class="lg:flex lg:mx-2 lg:space-x-3 xl:mx-6 xl:space-x-6">
      <!-- Left Side -->
      <div class="w-full lg:w-6/12 lg:mx-2 xl:mx-4 lg:mb-0">
        <!-- item Cards-->
        <div class="lg:mx-4">
          <div class="mb-6">
            <label
              for="fname"
              class="my_font block mb-2 text-sm my_font text-gray-900"
              >{{ $t("name") }}</label
            >
            <input
              type="text"
              id="name"
              class="my_font bg-gray-50 border border-gray-300 text-gray-900 placeholder-gray-700 my_font text-base rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
              required
              v-model="u_name"
            />
          </div>
          <div class="mb-6">
            <label
              for="ph_number"
              class="my_font block mb-2 text-sm my_font text-gray-900"
              >{{ $t("phone_number") }}</label
            >
            <input
              type="tel"
              id="ph_number"
              class="my_font bg-gray-50 border border-gray-300 text-gray-900 placeholder-gray-700 my_font text-base rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
              placeholder=""
              required
              v-model="u_phone"
            />
          </div>
          <div class="my_font cursor-pointer text-sm text-red-500">
            {{ $t(error_phone) }}
          </div>
          <div class="my_font cursor-pointer text-sm text-red-500">
            {{ $t(phone_short) }}
          </div>
          <div class="mb-6">
            <label
              for="ph_number2"
              class="my_font block mb-2 text-sm my_font text-gray-900"
              >{{ $t("second_phone_number") }}</label
            >
            <input
              type="number"
              id="ph_number2"
              class="my_font bg-gray-50 border border-gray-300 text-gray-900 placeholder-gray-700 my_font text-base rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
              required
              v-model="u_phone2"
            />
          </div>
          <div class="mb-6">
            <label
              for="password"
              class="my_font block mb-2 text-sm my_font text-gray-900"
              >{{ $t("password") }}</label
            >
            <input
              type="text"
              id="password"
              class="my_font bg-gray-50 border border-gray-300 text-gray-900 placeholder-gray-700 my_font text-base rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
              required
              v-model="password"
            />
          </div>
        </div>
      </div>

      <!-- Right Side -->
      <div class="w-full lg:w-6/12 lg:mx-2">
        <!--order information-->
        <div class="lg:mx-4">
          <div class="mb-6">
            <label
              for="dropdown"
              class="my_font block mb-2 text-sm my_font text-gray-900"
              >{{ $t("city") }}</label
            >
            <select
              id="countries"
              class="my_font bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
              v-model="u_city"
            >
              <option value="slemany" selected class="my_font">
                {{ $t("sulaymaniyah") }}
              </option>
              <option value="sulaymaniyah_out"  class="my_font">
                {{ $t("sulaymaniyah_out") }}
              </option>
              <option value="hawler" class="my_font">
                {{ $t("erbil") }}
              </option>
              <option value="dhok" class="my_font">{{ $t("duhok") }}</option>
              <option value="kirkuk" class="my_font">
                {{ $t("kirkuk") }}
              </option>
              <option value="halabja" class="my_font">
                {{ $t("halabja") }}
              </option>
              <option value="baghdad" class="my_font">
                {{ $t("baghdad") }}
              </option>
              <option value="anbar" class="my_font">{{ $t("anbar") }}</option>
              <option value="babl" class="my_font">{{ $t("babil") }}</option>
              <option value="basra" class="my_font">{{ $t("basra") }}</option>
              <option value="diwaniyah" class="my_font">
                {{ $t("diwaniyah") }}
              </option>
              <option value="ziqar" class="my_font">{{ $t("ziqar") }}</option>
              <option value="salah_al_din" class="my_font">
                {{ $t("salah_al_din") }}
              </option>
              <option value="qadisiyah" class="my_font">
                {{ $t("qadisiyah") }}
              </option>
              <option value="karbala" class="my_font">
                {{ $t("karbala") }}
              </option>
              <option value="muthanna" class="my_font">
                {{ $t("muthanna") }}
              </option>
              <option value="missan" class="my_font">
                {{ $t("missan") }}
              </option>
              <option value="najaf" class="my_font">{{ $t("najaf") }}</option>
              <option value="mosul" class="my_font">
                {{ $t("mosul") }}
              </option>
              <option value="wasit" class="my_font">{{ $t("wasit") }}</option>
            </select>
          </div>
          <div class="mb-6">
            <label
              for="Address"
              class="my_font block mb-2 text-sm my_font text-gray-900"
              >{{ $t("address") }}</label
            >
            <input
              type="text"
              id="address"
              name="address"
              class="my_font bg-gray-50 border border-gray-300 text-gray-900 placeholder-gray-700 my_font text-base rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
              placeholder="saholaka / nzik city star"
              required
              v-model="u_address"
            />
          </div>
          <div class="mb-6">
            <label
              for="instagram"
              class="my_font block mb-2 text-sm my_font text-gray-900"
              >{{ $t("instagram") }}</label
            >
            <input
              type="text"
              id="instagram"
              class="my_font bg-gray-50 border border-gray-300 text-gray-900 placeholder-gray-700 my_font text-base rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
              required
              v-model="u_instagram"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="my_font cursor-pointer text-sm text-red-500">
      {{ $t(something_wrong) }}
    </div>
    <div class="my_font cursor-pointer text-sm text-red-500">
      {{ $t(phone_exist) }}
    </div>
    <div class="w-full lg:w-6/12 mx-auto justify-center">
      <div
        class="my_font p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-200"
        role="alert"
        v-show="info_updated_successfully != ''"
      >
        {{ $t(info_updated_successfully) }}
      </div>
    </div>

    <button
      type="submit"
      class="my_font text-white bg-gradient-to-br from-primaryColorDark to-blue-300 focus:outline-none my_font rounded-lg text-lg px-5 py-3 flex justify-center md:w-1/6 mx-auto mt-10"
      @click="updateInfo"
    >
      <span class="my_font text-white text-lg text-center" v-if="!loadingBtn">
        {{ $t("save") }}</span
      >

      <div role="status" v-else>
        <svg
          aria-hidden="true"
          class="w-4 h-4 mr-2 text-gray-200 animate-spin fill-primaryColor"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
    </button>
  </div>
</template>

<script>
import Navbar from "./components/nav-bar.vue";

import "swiper/swiper-bundle.min.css";
import { SERVER_URL } from "../../config.js";
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "App",

  components: {
    Navbar,
  },
  data() {
    return {
      url: SERVER_URL,
      item: {},
      loadingBtn: false,
      isLoading: true,
      isLoadingOrders: true,
      isLoginShow: false,
      user: {},
      token: "",
      latestOrders: [],
      u_phone: "",
      u_name: "",
      u_phone2: "",
      u_address: "",
      u_instagram: "",
      password: "",
      u_city: "",
      error_phone: "",
      error_password: "",
      error_name: "",
      error_address: "",
      error_city: "",
      forgetDialog: false,
      something_wrong: "",
      password_short: "",
      phone_exist: "",
      phone_short: "",
      info_updated_successfully: "",
    };
  },
  computed: {
    isRtl() {
      return (
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == "ku" ||
        //if null
        localStorage.getItem("lang") == null ||
        //if empty
        localStorage.getItem("lang") == ""
      );
    },
  },
  created() {
    //get user from local storage
    this.user = JSON.parse(localStorage.getItem("user"));

    this.u_phone = this.user.u_phone;
    this.u_name = this.user.u_name;
    this.u_phone2 = this.user.u_phone2;
    this.u_address = this.user.u_address;
    this.u_instagram = this.user.u_instagram;
    this.u_city = this.user.u_city;
  },
  methods: {
    updateInfo() {
      //validate  phone and password
      if (this.u_name == "") {
        this.error_name = "please_enter_your_name";
        return;
      }
      this.error_name = "";
      if (this.u_phone == "") {
        this.error_phone = "please_enter_your_phone_number";
        return;
      }

      this.error_phone = "";
      if (this.u_phone.toString().length < 8) {
        this.phone_short = "phone_number_too_short";
        return;
      }
      this.phone_short = "";

      if (this.password != "" && this.password.length < 8) {
        this.password_short = "password_too_short_8";
        return;
      }
      this.password_short = "";

      if (this.u_city == "") {
        this.error_city = "please_enter_your_city";
        return;
      }
      this.error_city = "";

      if (this.u_address == "") {
        this.error_address = "please_enter_your_address";
        return;
      }
      this.error_address = "";

      this.loadingBtn = true;
      var data = {
        u_phone: this.u_phone.toString(),
        password: this.password,
        u_name: this.u_name,
        u_address: this.u_address,
        u_city: this.u_city,
        u_instagram: this.u_instagram,
        u_phone2: this.u_phone2,
      };

      axios
        .post(SERVER_URL + "api/user/update-info", data)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          // this.items = response.data;

          this.loadingBtn = false;
          this.u_phone_exist = "";
          this.password_short = "";
          this.phone_short = "";
          this.info_updated_successfully = "info_updated_successfully";
        })
        .catch((error) => {
          console.log(error.response.data.message);

          this.u_phone_exist = "";
          this.password_short = "";
          this.phone_short = "";
          this.info_updated_successfully = "";
          //if contain duplicate
          if (
            error.response.data.message.toString().includes("exists") ||
            error.response.data.message.toString().includes("Duplicate ")
          ) {
            this.u_phone_exist = "phone_exist";
            toast(this.$t("phone_exist"), {
              closeButton: true,
              position: "bottom-left",
              contentColor: "#000",
              content: error.response.data.message,
              type: "error",
            });
          } else {
            this.something_wrong = "something_wrong";
            toast(this.$t("something_wrong"), {
              closeButton: true,
              position: "bottom-left",
              contentColor: "#000",
              content: error.response.data.message,
              type: "error",
            });
          }
          this.loadingBtn = false;
        });
    },
  },
};
</script>

<style></style>
