<template>
  <Navbar />
  <div
    :class="
      'flex items-center justify-center h-screen bg-white p-4   ' +
      (isRtl ? 'sm:mr-64' : 'sm:ml-64')
    "
    v-show="!isDataLoaded"
  >
    <div class="flex items-center">
      <!-- Tailwind CSS spinner component -->

      <span class="my_font text-primaryColor text-xl">{{
        $t("loading...")
      }}</span>
      <div role="status">
        <svg
          aria-hidden="true"
          class="w-8 h-8 mx-2 text-gray-200 animate-spin fill-primaryColor"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
      </div>
    </div>
  </div>
  <div
    :class="'p-4 mt-20  ' + (isRtl ? 'sm:mr-64' : 'sm:ml-64')"
    v-show="isDataLoaded"
  >
    <div class="lg:flex lg:mx-2 lg:space-x-3 xl:mx-6 xl:space-x-6">
      <!-- Left Side -->
      <div
        class="w-full lg:w-5/12 lg:mx-1 xl:mx-2 lg:mt-4 space-y-8 mb-20 lg:mb-0"
      >
        <!-- item Cards-->

        <div
          class="flex w-98per rounded-xl bg-gray-50 px-2 py-1 justify-between"
          v-for="(item, index) in order.sold_items"
          :key="index"
        >
          <div class="flex items-center">
            <img
              :src="fileUrl + 'kcstore.online/public/storage/' + item.i_image"
              class="w-16 rounded-xl"
              alt="img"
            />
            <div class="w-1"></div>
            <p
              class="text-base sm:text-base md:text-base lg:text-base xl:text-base text-gray-700 font-medium my-auto"
            >
              {{ item.i_name }}
            </p>
          </div>

          <div class="flex">
            <p class="text-base w-12 text-gray-700 my-auto mx-1">
              {{ formatNumber(item.si_price) }}
            </p>
            <p
              class="text-base text-gray-700 w-2 my-auto mx-4 sm:mx-2 md:mx-4 lg:mx-2 xl:mx-4"
            >
              *
            </p>
            <div
              class="h-10 text-base px-4 bg-white my-auto flex items-center justify-between rounded-lg relatives"
            >
              <span id="amount" class="select-none"> {{ item.si_count }}</span>
            </div>

            <p class="text-base w-12 text-gray-700 font-semibold my-auto mx-2">
              {{ formatNumber(item.si_price * item.si_count) }}
            </p>
          </div>
        </div>

        <!--total price-->
        <hr class="h-px text-gray-600 mx-1" />
        <div class="space-y-4">
          <div class="flex justify-between mx-3">
            <div class="flex">
              <p class="my_font text-base text-gray-700 font-medium my-auto">
                {{ $t("item_count") }} :
              </p>
              <span
                class="my_font text-base text-gray-700 font-semibold px-1"
                v-if="order != null"
                >{{ countProducts }}</span
              >
            </div>
            <div class="flex">
              <p class="my_font text-base text-gray-700 font-medium my-auto">
                {{ $t("products_price") }} :
              </p>
              <span
                class="my_font text-base text-gray-700 font-semibold px-1"
                >{{ formatNumber(priceAll()) }}</span
              >
            </div>
          </div>

          <div class="flex justify-between mx-3" v-show="order.o_discount > 0">
            <p class="text-base text-red-500 font-medium my-auto"></p>
            <div class="flex">
              <p class="my_font text-base text-red-500 font-medium my-auto">
                {{ $t("total_discount") }} :
              </p>
              <span class="my_font text-base text-red-500 font-semibold px-1">{{
                formatNumber(priceAll() - priceAll() * (1 - order.o_discount))
              }}</span>
            </div>
          </div>
          <div class="flex justify-between mx-3">
            <p class="text-base text-gray-700 font-medium my-auto"></p>
            <div class="flex">
              <p class="my_font text-base text-gray-700 font-medium my-auto">
                {{ $t("delivery_price") }} :
              </p>
              <span
                class="my_font text-base text-gray-700 font-semibold px-1"
                >{{ formatNumber(order.o_delivery_fees) }}</span
              >
            </div>
          </div>
          <hr class="h-px text-gray-600 mx-1" />
          <div class="flex justify-between mx-3">
            <p class="my_font text-lg text-gray-700 font-semibold my-auto">
              {{ $t("total_price") }}
            </p>
            <p class="my_font text-lg text-gray-800 font-semibold my-auto">
              {{
                formatNumber(
                  priceAll() +
                    parseToInt(order.o_delivery_fees) -
                    (priceAll() - priceAll() * (1 - order.o_discount))
                )
              }}
            </p>
          </div>
        </div>
      </div>

      <!-- Right Side -->
      <div class="w-full lg:w-7/12 lg:mx-2 sm:mt-8 md:mt-4">
        <!--order information-->
        <div class="lg:mx-4">
          <div class="mb-6 flex space-x-2 lg:space-x-6">
            <div class="flex-1">
              <label
                for="oederId"
                class="my_font block mb-2 text-base font-medium text-gray-900"
                >{{ $t("order_id") }}</label
              >
              <div
                id="oederId"
                class="my_font w-fit text-3 md:text-3.5 rounded-lg font-medium bg-gradient-to-br from-primaryColor to-primaryColorDark px-4 py-2 md:px-6 md:py-3 text-white"
              >
                #{{ order.o_id }}
              </div>
            </div>

            <div class="flex-1">
              <label
                for="status"
                class="my_font block mb-2 text-base font-medium text-gray-900"
                >{{ $t("date") }}</label
              >
              <span
                id="status"
                class="w-fit flex justify-center bg-gradient-to-br from-gray-100 to-gray-200 px-2 sm:px-3 py-2 md:px-6 lg:px-3 xl:px-6 md:py-3 rounded-lg text-white"
              >
                <span
                  class="my_font text-3 sm:text-3.5 font-medium text-gray-700"
                  >{{ localTime(order.created_at) }}</span
                >
              </span>
            </div>

            <div class="flex-1">
              <label
                for="status"
                class="my_font block mb-2 text-base font-medium text-gray-900"
                >{{ $t("order_status") }}</label
              >
              <span
                id="status"
                :class="
                  'w-fit flex justify-center px-4 py-2 md:px-6 md:py-3 rounded-lg text-white ' +
                  (order.o_status == 'pending'
                    ? 'bg-gradient-to-br from-gray-100 to-gray-200 text-gray-600'
                    : order.o_status == 'accepted'
                    ? 'bg-gradient-to-br from-green-100 to-green-200 text-green-600'
                    : order.o_status == 'canceled'
                    ? 'bg-gradient-to-br from-orange-100 to-orange-200 text-orange-600'
                    : 'bg-gradient-to-br from-red-100 to-red-200 text-red-600')
                "
              >
                <font-awesome-icon
                  :class="
                    'w-3 h-3 my-auto mx-1 ' +
                    (order.o_status == 'pending'
                      ? 'text-gray-600'
                      : order.o_status == 'accepted'
                      ? 'text-green-600'
                      : order.o_status == 'canceled'
                      ? 'text-orange-600'
                      : 'text-red-600')
                  "
                  :icon="[
                    'fas',
                    order.o_status == 'pending'
                      ? 'clock'
                      : order.o_status == 'accepted'
                      ? 'check'
                      : order.o_status == 'canceled'
                      ? 'rotate-left'
                      : 'xmark',
                  ]"
                  size="sm"
                />
                <div class="w-3"></div>
                <span
                  :class="
                    'my_font text-3.5 font-medium ' +
                    (order.o_status == 'pending'
                      ? 'text-gray-600'
                      : order.o_status == 'accepted'
                      ? 'text-green-600'
                      : order.o_status == 'canceled'
                      ? 'text-orange-600'
                      : 'text-red-600')
                  "
                  >{{ $t(order.o_status + "") }}</span
                >
              </span>
            </div>
          </div>
          <div class="mb-6 flex">
            <div class="flex-1">
              <label
                for="city"
                class="my_font block mb-2 text-base font-medium text-gray-900"
                >{{ $t("city") }}</label
              >
              <input
                type="text"
                :value="$t(getCity(order.o_city+'') + '')"
                class="my_font bg-gray-50 border border-gray-200 text-gray-900 placeholder-gray-700 font-medium text-base rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
                disabled
              />
            </div>
            <div class="mx-4 lg:mx-8"></div>
            <div class="flex-1">
              <label
                for="Address"
                class="my_font block mb-2 text-base font-medium text-gray-900"
                >{{ $t("address") }}</label
              >
              <input
                type="text"
                id="Address"
                class="my_font bg-gray-50 border border-gray-200 text-gray-900 placeholder-gray-700 font-medium text-base rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
                :value="order.o_address"
                disabled
              />
            </div>
          </div>
          <div class="mb-6" v-show="order.o_note != null && order.o_note != ''">
            <label
              for=""
              class="my_font block mb-2 text-base font-medium text-gray-900"
              >{{ $t("note") }}</label
            >
            <textarea
              id="note"
              rows="3"
              class="my_font block p-2.5 w-full text-base text-gray-900 placeholder-gray-700 bg-gray-50 rounded-lg border border-gray-200 focus:ring-primaryColor focus:border-primaryColor"
              disabled
              v-model="order.o_note"
            >
            </textarea>
          </div>
          <div
            class="mb-6"
            v-show="
              order.o_message != null &&
              order.o_message != '' &&
              order.o_message != 'null'
            "
          >
            <label
              for="message"
              class="my_font block mb-2 text-base font-medium text-gray-900"
              >{{ $t("message_reponse") }}</label
            >
            <textarea
              id="message"
              rows="3"
              class="my_font block p-2.5 w-full text-base text-gray-900 placeholder-gray-700 bg-gray-50 rounded-lg border border-gray-200 focus:ring-primaryColor focus:border-primaryColor"
              disabled
              v-model="order.o_message"
            ></textarea>
          </div>

          <div
            class="mb-6 flex space-x-4 lg:space-x-8"
            v-show="order.o_discount != 0"
          >
            <div class="flex-1">
              <label
                for="city"
                class="my_font block mb-2 text-base font-medium text-gray-900"
                >{{ $t("discount") }}</label
              >
              <input
                type="text"
                :value="
                  convertFloatToPercentage(order.o_discount) +
                  ' %' +
                  $t(order.pc_id != null ? order.pc_id : '')
                "
                class="my_font bg-gray-50 border border-gray-200 text-gray-900 placeholder-gray-700 font-medium text-base rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
                disabled
              />
            </div>
            <div class="flex-1"></div>
          </div>
          <div
            class="flex items-center p-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50"
            role="alert"
            v-show="order.is_cancelable"
          >
            <svg
              class="flex-shrink-0 inline w-4 h-4 mx-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
              />
            </svg>

            <div class="my_font text-base">
              {{
                $t(
                  "warning_you_can_cancel_order_until_2_hour_after_you_ordered"
                )
              }}
            </div>
          </div>
          <div
            class="flex items-center p-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50"
            role="alert"
            v-show="order_can_not_be_canceled"
          >
            <svg
              class="flex-shrink-0 inline w-4 h-4 mx-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
              />
            </svg>

            <div class="my_font text-base">
              {{ $t("order_can_not_be_canceled") }}
            </div>
          </div>
          <br />
          <button
            type="submit"
            class="text-white bg-gradient-to-br from-red-700 to-red-500 focus:outline-none font-medium rounded-lg text-base px-5 py-3 flex justify-center w-1/2 mx-auto"
            v-show="order.is_cancelable"
            @click="cancelOrder"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              class="w-3 h-3 text-white my-auto"
              viewBox="0 0 512.021 512.021"
              v-if="!cancelButtonLoading"
            >
              <path
                d="M301.258 256.01 502.645 54.645c12.501-12.501 12.501-32.769 0-45.269-12.501-12.501-32.769-12.501-45.269 0L256.01 210.762 54.645 9.376c-12.501-12.501-32.769-12.501-45.269 0s-12.501 32.769 0 45.269L210.762 256.01 9.376 457.376c-12.501 12.501-12.501 32.769 0 45.269s32.769 12.501 45.269 0L256.01 301.258l201.365 201.387c12.501 12.501 32.769 12.501 45.269 0 12.501-12.501 12.501-32.769 0-45.269L301.258 256.01z"
              />
            </svg>
            <div class="w-3" v-if="!cancelButtonLoading"></div>
            <span
              class="my_font text-lg text-white"
              v-if="!cancelButtonLoading"
              >{{ $t("cancel_order") }}</span
            >

            <div role="status" v-if="cancelButtonLoading">
              <svg
                aria-hidden="true"
                class="w-4 h-4 mr-2 text-gray-200 animate-spin fill-primaryColor"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "./components/nav-bar.vue";

import "swiper/swiper-bundle.min.css";
import { SERVER_URL } from "../../config.js";
import { SERVER_FILE_URL } from "../../config.js";

import axios from "axios";
//import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "App",

  components: {
    Navbar,
  },
  data() {
    return {
      url: SERVER_URL,
      fileUrl: SERVER_FILE_URL,
      isDataLoaded: false,
      order: {
        sold_items: [],

        order_can_not_be_canceled: false,
      },
      cancelButtonLoading: false,
      cities: [
        { name: "slemany", translation: "سلێمانی" },
        { name: "dhok", translation: "دهۆک" },
        { name: "kirkuk", translation: "کەرکووک" },
        { name: "hawler", translation: "هەولێر" },
        { name: "baghdad", translation: "بەغداد" },
        { name: "mosul", translation: "مۆسڵ" },
        { name: "basra", translation: "بصرە" },
        { name: "diwaniyah", translation: "دیوانیه" },
        { name: "anbar", translation: "انبار" },
        { name: "karbala", translation: "کەربەلاء" },
        { name: "najaf", translation: "نەجف" },
        { name: "qadisiyah", translation: "قادسیە" },
        { name: "wasit", translation: "واسط" },
        { name: "babil", translation: "بابل" },
        { name: "halabja", translation: "هەڵەبجە" },
        { name: "missan", translation: "میسان" },
        { name: "muthanna", translation: "موسەنا" },
        { name: "ziqar", translation: "زیقار" },
        { name: "salah_al_din", translation: "صلاح الدین" },
      ],
    };
  },
  computed: {
    isRtl() {
      return (
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == "ku" ||
        //if null
        localStorage.getItem("lang") == null ||
        //if empty
        localStorage.getItem("lang") == ""
      );
    },
    countProducts() {
      var count = 0;
      if (this.order.sold_items.length > 0) {
        this.order.sold_items.forEach((item) => {
          count += parseInt(item.si_count);
        });
      }
      return count;
    },
  },
  created() {
    this.getItem();
  },
  methods: {
    getCity(name) {
      for (let index = 0; index < this.cities.length; index++) {
        const element = this.cities[index];
        if (element.name == name) {
          return element.translation;
        }
      }
      return name;
    },
    formatNumber(num) {
      if (num == null) return 0;
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    //a function to convert timestamp to date and local time
    localTime(timeStamp) {
      let date = new Date(timeStamp);
      var local = date.toLocaleString();
      //convert 7/9/2023, 3:14:47 AM to 7-9-2023 3:14:47
      local = local.replace(/,/g, "");
      local = local.replace(/\//g, "-");
      local = local.replace(/ AM/g, "");
      local = local.replace(/ PM/g, "");
      //then reverse it to 2023-9-7 3:14:47
      local = local.split(" ").reverse().join(" ");
      return local;
    },
    getItem() {
      axios
        .post(SERVER_URL + "api/order/show/" + this.$route.params.id)
        .then((response) => {
          // this.items = response.data;
          this.order = response.data;
          this.isDataLoaded = true;
        })
        .catch((error) => {
          console.log(error);

          //check status code
          if (error.response.status == 404) {
            window.location.href = "/profile/orders";
          }
        });
    },
    convertFloatToPercentage(num) {
      return (num * 100).toFixed(2);
    },
    priceAll() {
      let price = 0;
      this.order.sold_items.forEach((item) => {
        price += item.si_price * item.si_count;
      });
      return price;
    },
    cancelOrder() {
      //confirm canceling
      if (!confirm(this.$t("are_you_sure_you_want_to_cancel_order"))) return;
      this.cancelButtonLoading = true;
      axios
        .post(SERVER_URL + "api/order/cancel/" + this.$route.params.id)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          window.location.href = "/profile/orders/" + this.$route.params.id;
          this.cancelButtonLoading = false;
        })
        .catch((error) => {
          console.log(error);

          //check status code
          if (error.response.status == 402) {
            this.order_can_not_be_canceled = true;
          }
        });
    },
    parseToInt(num) {
      return parseInt(num);
    },
  },
};
</script>

<style></style>
