<template>
  <div
    :class="
      (show ? '  opacity-1 ' : 'pointer-events-none opacity-0') +
      ' fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60  backdrop-blur-sm transition-opacity duration-300 '
    "
  >
    <div
      data-dialog="sign-in-dialog"
      class="relative mx-auto flex w-full max-w-[24rem] flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md"
    >
      <h3
        class="my_font text-xl font-semibold text-center pt-3 pb-2 leading-snug tracking-normal bg-clip-text text-gray-700 antialiased"
      >
        {{ $t("sign_in") }}
      </h3>
      <!-- close icon -->
      <button
        class="absolute top-3 right-3 focus:outline-none"
        @click="closeModal"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-gray-700 hover:text-gray-900"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </button>

      <div class="flex flex-col gap-4 p-6">
        <div class="relative h-11 w-full min-w-[200px]">
          <input
            class="my_font peer h-full w-full rounded-md border border-gray-300 border-t-transparent bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-primaryColorDark focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
            id="phone"
            type="number"
            autocomplete="tel"
            required
            v-model="phone"
          />
          <label
            class="my_font behtmlFore:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-primaryColorDark peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-primaryColorDark peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-primaryColorDark peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
          >
            {{ $t("phone_number") }}
          </label>
        </div>
        <div class="my_font cursor-pointer text-sm text-red-500">
          {{ $t(error_phone) }}
        </div>
        <div class="relative h-11 w-full min-w-[200px]">
          <input
            class="my_font peer h-full w-full rounded-md border border-gray-300 border-t-transparent bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-primaryColorDark focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
            type="password"
            name="password"
            id="password"
            required
            autocomplete="current-password"
            v-model="password"
          />
          <label
            class="my_font behtmlFore:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-primaryColorDark peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-primaryColorDark peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-primaryColorDark peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
          >
            {{ $t("password") }}
          </label>
        </div>
        <div class="my_font cursor-pointer text-sm text-red-500">
          {{ $t(error_password) }}
        </div>
        <div class="my_font cursor-pointer text-sm text-red-500">
          {{ $t(phone_or_password_wrong) }}
        </div>
      </div>

      <div class="p-6 pt-0">
        <button
          class="my_font block w-full select-none rounded-lg bg-gradient-to-tr from-primaryColorDark to-2B4168-400 py-3 px-6 text-center align-middle font-sans text-base font-bold uppercase text-white shadow-md shadow-primaryColorLight transition-all hover:shadow-lg hover:shadow-primaryColor active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
          data-ripple-light="true"
          @click="loginFunc"
        >
          {{ $t("sign_in") }}
        </button>
        <p
          class="my_font mt-6 flex justify-center text-sm font-light leading-normal text-inherit antialiased"
        >
          {{ $t("dont_have_an_account_yet") }}
          <a
            href="/signup"
            class="my_font ml-1 block text-sm font-bold leading-normal tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-primaryColor to-primaryColorDark antialiased"
          >
            {{ $t("sign_up") }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { SERVER_URL } from "../../config.js";
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      url: SERVER_URL,
      loadingBtn: false,
      phone: "",
      password: "",
      error_phone: "",
      error_password: "",
      forgetDialog: false,
      phone_or_password_wrong: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    loginFunc() {
      //validate  phone and password
      if (this.phone == "") {
        this.error_phone = "please_enter_your_phone_number";
        return;
      }
      this.error_phone = "";
      if (this.password == "") {
        this.error_password = "please_enter_your_password";
        return;
      }
      this.error_password = "";
      this.loadingBtn = true;
      axios
        .post(SERVER_URL + "api/user/signin", {
          u_phone: this.phone.toString(),
          password: this.password,
        })
        .then((response) => {
          // this.items = response.data;
      
          if (response.data.success == true) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            //reload in same page
            location.reload();
          } else {
            // ToastOptions
          }
          this.loadingBtn = false;
        })
        .catch((error) => {
          console.log(error);
          toast(this.$t("phone_or_password_wrong"), {
            closeButton: true,
            position: "bottom-left",
            contentColor: "#000",
            content: error.response.data.message,
            type: "error",
          });
          this.phone_or_password_wrong = "phone_or_password_wrong";
          this.loadingBtn = false;
        });
    },
  },
};
</script>

<style></style>
