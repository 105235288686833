<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
    <meta name="description" :content="description" />
  </metainfo>

  <Navbar />

  <section
    class="bg-[url('/assets/image/background.jpg')] md:bg-center bg-cover bg-no-repeat"
  >
    <div
      class="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0"
    >
      <br />
      <br /><br /><br />
      <br />
      <br />
      <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1
            class="my_font text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl"
          >
            {{ $t("sign_up") }}
          </h1>
          <form class="space-y-4 md:space-y-6">
            <div>
              <label
                for="name"
                class="my_font block mb-2 text-base font-medium text-gray-900"
                >{{ $t("name") }}</label
              >
              <input
                type="text"
                name="name"
                id="name"
                class="my_font bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
                required=""
                v-model="name"
              />
              <div class="my_font cursor-pointer text-sm text-red-500">
                {{ $t(error_name) }}
              </div>
            </div>
            <div>
              <label
                for="phone"
                class="my_font block mb-2 text-base font-medium text-gray-900"
                >{{ $t("phone_number") }}</label
              >
              <input
                type="number"
                name="phone"
                id="phone"
                class="my_font bg-gray-50 border border-gray-300 text-gray-900 sm:text-base rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
                required=""
                v-model="phone"
              />
              <div class="my_font cursor-pointer text-sm text-red-500">
                {{ $t(error_phone) }}
              </div>
              <div class="my_font cursor-pointer text-sm text-red-500">
                {{ $t(phone_short) }}
              </div>
            </div>
            <div>
              <label
                for="password"
                class="my_font block mb-2 text-base font-medium text-gray-900"
                >{{ $t("password") }}</label
              >
              <input
                type="password"
                name="password"
                id="password"
                class="my_font bg-gray-50 border border-gray-300 text-gray-900 sm:text-base rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
                required=""
                v-model="password"
              />
              <div class="my_font cursor-pointer text-sm text-red-500">
                {{ $t(error_password) }}
              </div>
              <div class="my_font cursor-pointer text-sm text-red-500">
                {{ $t(password_short) }}
              </div>
            </div>
            <div>
              <label
                for="city"
                class="my_font block mb-2 text-base font-medium text-gray-900"
                >{{ $t("city") }}</label
              >

              <select
                id="countries"
                class="my_font bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
                v-model="city"
              >
                <option value="slemany" selected class="my_font">
                  {{ $t("sulaymaniyah") }}
                </option>
                <option value="sulaymaniyah_out"  class="my_font">
                  {{ $t("sulaymaniyah_out") }}
                </option>
                <option value="hawler" class="my_font">
                  {{ $t("erbil") }}
                </option>
                <option value="dhok" class="my_font">{{ $t("duhok") }}</option>
                <option value="kirkuk" class="my_font">
                  {{ $t("kirkuk") }}
                </option>
                <option value="halabja" class="my_font">
                  {{ $t("halabja") }}
                </option>
                <option value="baghdad" class="my_font">
                  {{ $t("baghdad") }}
                </option>
                <option value="anbar" class="my_font">{{ $t("anbar") }}</option>
                <option value="babl" class="my_font">{{ $t("babil") }}</option>
                <option value="basra" class="my_font">{{ $t("basra") }}</option>
                <option value="diwaniyah" class="my_font">
                  {{ $t("diwaniyah") }}
                </option>
                <option value="ziqar" class="my_font">{{ $t("ziqar") }}</option>
                <option value="salah_al_din" class="my_font">
                  {{ $t("salah_al_din") }}
                </option>
                <option value="qadisiyah" class="my_font">
                  {{ $t("qadisiyah") }}
                </option>
                <option value="karbala" class="my_font">
                  {{ $t("karbala") }}
                </option>
                <option value="muthanna" class="my_font">
                  {{ $t("muthanna") }}
                </option>
                <option value="missan" class="my_font">
                  {{ $t("missan") }}
                </option>
                <option value="najaf" class="my_font">{{ $t("najaf") }}</option>
                <option value="mosul" class="my_font">
                  {{ $t("mosul") }}
                </option>
                <option value="wasit" class="my_font">{{ $t("wasit") }}</option>
              </select>
              <div class="my_font cursor-pointer text-sm text-red-500">
                {{ $t(error_city) }}
              </div>
            </div>
            <div>
              <label
                for="address"
                class="my_font block mb-2 text-base font-medium text-gray-900"
                >{{ $t("address") }}</label
              >
              <input
                type="address"
                name="address"
                id="address"
                class="my_font bg-gray-50 border border-gray-300 text-gray-900 sm:text-base rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
                required=""
                v-model="address"
              />
              <div class="my_font cursor-pointer text-sm text-red-500">
                {{ $t(error_address) }}
              </div>
            </div>
            <div class="my_font cursor-pointer text-sm text-red-500">
              {{ $t(something_wrong) }}
            </div>
            <div class="my_font cursor-pointer text-sm text-red-500">
              {{ $t(phone_exist) }}
            </div>
            <div
              class="my_font w-full text-white bg-primaryColor hover:bg-primaryColorDark focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center"
              @click="sigupFunc"
            >
              <span
                class="my_font text-white text-lg text-center"
                v-if="!loadingBtn"
              >
                {{ $t("sign_up") }}</span
              >

              <div role="status" v-else>
                <svg
                  aria-hidden="true"
                  class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-primaryColor"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <p class="my_font text-base font-light text-gray-500">
              {{ $t("already_have_account") }}
              <router-link
                to="/signin"
                class="my_font text-basefont-medium text-primaryColorDark hover:underline"
                >{{ $t("sign_in") }}</router-link
              >
            </p>
          </form>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
  </section>

  <Footer />
</template>

<script>
import Navbar from "../components/nav-bar.vue";
import Footer from "../components/footer-app.vue";
import { SERVER_URL } from "../../config.js";
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useMeta } from "vue-meta";

export default {
  name: "App",

  setup() {
    useMeta({
      title: "دروستکردنی هەژمار",
      description:
        "پێستت بەشێکە لە تەندروستیت، تەندورستی پێستت بە ئێمە بسپێرە، بەهەبوونی زیاتر لە 2 ساڵ کارکردن لە بوارەکەدا و دابینکردنی بەناوبانگترین و چاکترین بەرهەمەکان لە وڵاتانی کۆریا و یابان و ولاتانی ئەوروپاوە، دەتوانین یارمەتیت بدەین بۆ بەدەستهێنانی پێستی دڵخواز و تەندروستی خۆت",
      //image
    });
  },
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      url: SERVER_URL,
      loadingBtn: false,
      phone: "",
      password: "",
      name: "",
      address: "",
      city: "",
      error_phone: "",
      error_password: "",
      error_name: "",
      error_address: "",
      error_city: "",
      forgetDialog: false,
      something_wrong: "",
      password_short: "",
      phone_exist: "",
      phone_short: "",
    };
  },
  mounted() {
    //scrol to top
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    sigupFunc() {
      //validate  phone and password
      if (this.name == "") {
        this.error_name = "please_enter_your_name";
        return;
      }
      this.error_name = "";
      if (this.phone == "") {
        this.error_phone = "please_enter_your_phone_number";
        return;
      }

      this.error_phone = "";
      if (this.phone.toString().length < 8) {
        this.phone_short = "phone_number_too_short";
        return;
      }
      this.phone_short = "";
      if (this.password == "") {
        this.error_password = "please_enter_your_password";
        return;
      }
      this.error_password = "";
      if (this.password.length < 8) {
        this.password_short = "password_too_short_8";
        return;
      }
      this.password_short = "";

      if (this.city == "") {
        this.error_city = "please_enter_your_city";
        return;
      }
      this.error_city = "";

      if (this.address == "") {
        this.error_address = "please_enter_your_address";
        return;
      }
      this.error_address = "";

      this.loadingBtn = true;

      axios
        .post(SERVER_URL + "api/user/signup", {
          u_phone: this.phone.toString(),
          password: this.password,
          u_name: this.name,
          u_address: this.address,
          u_city: this.city,
        })
        .then((response) => {
          // this.items = response.data;

          if (response.data.success == true) {
            localStorage.setItem("token", response.data.data.u_token);
            localStorage.setItem("user", JSON.stringify(response.data.data));
            window.location.href = "/profile";
          } else {
            // ToastOptions
          }
          this.loadingBtn = false;
          this.phone_exist = "";
        })
        .catch((error) => {
          console.log(error);

          this.phone_exist = "";
          //if contain duplicate
          if (error.response.data.error.toString().includes("exists")) {
            this.phone_exist = "phone_exist";
            toast(this.$t("phone_exist"), {
              closeButton: true,
              position: "bottom-left",
              contentColor: "#000",
              content: error.response.data.message,
              type: "error",
            });
          } else {
            this.something_wrong = "something_wrong";
            toast(this.$t("something_wrong"), {
              closeButton: true,
              position: "bottom-left",
              contentColor: "#000",
              content: error.response.data.message,
              type: "error",
            });
          }
          this.loadingBtn = false;
        });
    },
  },
};
</script>
