<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
    <meta name="description" :content="description" />
  </metainfo>

  <Navbar />

  <!--start seach field display on mobile-->
  <div
    class="flex md:hidden mt-20 items-center border border-gray-400 rounded-4 px-4 py-2 mx-4"
  >
    <input
      class="my_font flex-1 text-gray700 appearance-none bg-transparent focus:outline-none w-40"
      type="text"
      v-model="search"
      :placeholder="$t('search')"
      @input="searchProduct"
    />
    <svg
      class="w-5 h-5 text-gray-700"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path d="M22 22l-6-6" />
      <circle cx="10" cy="10" r="8" />
    </svg>
  </div>

  <nav class="w-full rounded-md mt-8 md:mt-24 pt-4">
    <ol class="list-reset flex mx-7 lg:mx-12">
      <li>
        <div
          class="my_font text-lg text-primaryColorDark mary transition duration-150 ease-in-out active:text-primaryColor"
        >
          {{ currentOpenedCategory }}
        </div>
      </li>
      <li>
        <span
          class="my_font font-bold mx-2 text-neutral-500 dark:text-neutral-400"
          v-if="currentOpenedCategory != '' && currentOpenedSubCategory != ''"
          >></span
        >
      </li>
      <li>
        <div
          class="my_font text-lg text-gray-600 transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 -text"
          v-if="currentOpenedSubCategory != ''"
        >
          {{ getSubCatgeoryNameFromId(currentOpenedSubCategory) }}
        </div>
      </li>
    </ol>
  </nav>

  <!-- SIdebarrr -->
  <div class="py-4">
    <div class="flex justify-between mx-8 items-center justify-items-center">
      <h2 class="my_font text-xl font-normal mb-4 mx-4 hidden lg:flex">
        {{ $t("categories") }}
      </h2>
      <!-- Filter Sidebar -->

      <button
        @click="switchFilterSidebar"
        class="focus:outline-none block lg:hidden show-sidebar"
        type="button"
        data-toggle="collapse"
        data-target="#categorySupportedContent"
        aria-controls="categorySupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          class="w-7 h-7 text-gray-700"
          fill="currentColor"
        >
          <path
            d="M0 416c0 17.7 14.3 32 32 32h54.7c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H233.3c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48H32c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1-64 0zm192-160a32 32 0 1 1 64 0 32 32 0 1 1-64 0zm32-80c-32.8 0-61 19.7-73.3 48H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h246.7c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48H480c17.7 0 32-14.3 32-32s-14.3-32-32-32h-54.7c-12.3-28.3-40.5-48-73.3-48zm-160-48a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48H32C14.3 64 0 78.3 0 96s14.3 32 32 32h86.7c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H265.3z"
          />
        </svg>
      </button>

      <div class="flex items-center justify-items-center">
        <div
          class="hidden md:flex border border-gray-400 rounded-4 px-4 py-2 mb-2 mx-4"
        >
          <input
            class="my_font text-gray700 appearance-none bg-transparent focus:outline-none w-40"
            type="text"
            v-model="search"
            :placeholder="$t('search')"
            @input="searchProduct"
          />
          <svg
            class="w-5 h-5 text-gray-700"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M22 22l-6-6" />
            <circle cx="10" cy="10" r="8" />
          </svg>
        </div>

        <a
          class="my_font text-base font-normal mb-4 mx-4 cursor-pointer sort-by-dropdown"
          id="dropdown-toggle-sort"
          @click="openSortByDropDown"
        >
          {{ $t(currentSort.name) }}
          <span>
            <svg
              class="fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </span>
        </a>
      </div>
    </div>
    <!--sort by dropDown-->
    <div
      v-show="isSortByDropDownOpen"
      id="dropdown-menu-sort"
      :class="
        'origin-top-right absolute  w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-30 ' +
        (isRtl ? 'left-6' : 'right-6')
      "
    >
      <div
        class="py-1"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="language-dropdown"
      >
        <div
          class="curser-pointer my_font block px-4 py-2 text-base text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          v-for="(sort, index) in sortList"
          :key="index"
          @click="sortBy(sort)"
        >
          {{ $t(sort.name) }}
        </div>
      </div>
    </div>

    <!-- add line -->
    <div class="border-b-1 border-gray-400 mx-8"></div>
    <div class="flex">
      <div
        :class="
          'fixed top-10 lg:top-0 h-full overflow-y-auto py-12 lg:py-0 lg:relative w-3/5 md:w-2/5 lg:w-1/5 pr-0 lg:pr-8 my-4 mx-0 lg:mx-8 bg-white shadow-md lg:shadow-none rounded-lg lg:rounded-0 -mt-2 lg:-mt-0  z-20 show-sidebar ' +
          (isShowSidebar ? 'lg:block' : ' hidden lg:block') +
          ' '
        "
      >
        <div class="mb-6" id="category-card">
          <div class="flex lg:hidden justify-between mx-4">
            <span class="my_font text-5 font-medium text-gray-700">{{
              $t("filters")
            }}</span>

            <button id="hideButton" @click="isShowSidebar = false">
              <svg
                class="fill-current h-8 w-8 text-gray-700 cursor-pointer"
                viewBox="0 0 20 20"
              >
                <path
                  d="M6.22 6.22a.75.75 0 011.06 0L10 8.94l2.72-2.72a.75.75 0 111.06 1.06L11.06 10l2.72 2.72a.75.75 0 11-1.06 1.06L10 11.06l-2.72 2.72a.75.75 0 01-1.06-1.06L8.94 10 6.22 7.28a.75.75 0 010-1.06z"
                ></path>
              </svg>
            </button>
          </div>
          <div class="block lg:hidden">
            <hr class="my-2 h-px text-gray-700 mx-8" />
          </div>

          <ul class="space-y-2">
            <li v-for="(category, index) in categorys" :key="index">
              <div
                :class="
                  'cursor-pointer flex justify-between text-gray-800 px-4 py-3 mx-2 rounded-md dropdown-toggle-category' +
                  (currentOpenedCategory == index
                    ? ' text-primaryColorDark'
                    : '')
                "
                @click="changeCategory(index, '', false)"
              >
                <div class="flex cursor-pointer">
                  <span
                    class="cursor-pointer my_font text-xl dropdown-toggle-category"
                    >{{ index }}</span
                  >
                </div>

                <span>
                  <svg
                    class="fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </span>
              </div>
              <ul
                class="dropdown-menu-category space-y-2 mx-3"
                v-if="currentOpenedCategory == index"
              >
                <li
                  v-for="(sub, i) in category"
                  :key="i"
                  class="curser-pointer text-red"
                >
                  <div
                    :class="
                      'my_font px-3 py-1 page-scroll cursor-pointer ' +
                      (category[i].sc_id == currentOpenedSubCategory
                        ? '-text'
                        : '')
                    "
                    @click="changeCategory(index, category[i].sc_id, true)"
                  >
                    {{ category[i].sc_name }}
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- Rest of the code -->
      </div>

      <!-- Product List -->
      <div class="w-full lg:w-4/5 pl-0 lg:pl-8 my-4 mx-4">
        <!-- <h2 class="text-lg font-semibold mb-4">Product Category</h2> -->
        <div
          class="md:my-4 grid grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-2 sm:gap-4 md:gap-8 justify-items-center items-center"
        >
          <!-- <h2 class="text-lg font-semibold mb-4">Product Category</h2> -->

          <div
            :class="
              'w-40 sm:w-60 md:w-80 h-90 p-3 rounded-lg shadow animate-pulse bg-white border border-gray-200 md:p-6 ' +
              (isLoadSearch == false && searchList.data.length == 0
                ? ''
                : ' hidden')
            "
            data-aos="fade-up"
            data-aos-duration="1500"
            v-for="(item, index) in loadingList"
            :key="index"
          >
            <div role="status">
              <div
                class="flex items-center justify-center h-64 mb-4 bg-gray-300 rounded"
              >
                <svg
                  class="w-10 h-10 text-gray-200"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path
                    d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z"
                  />
                  <path
                    d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"
                  />
                </svg>
              </div>
              <div class="flex items-center mt-4 space-x-3">
                <div>
                  <div class="h-2.5 bg-gray-200 rounded-full w-32 mb-2"></div>
                  <div class="w-48 h-2 bg-gray-200 rounded-full"></div>
                </div>
              </div>
              <div class="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
              <div class="h-2 bg-gray-200 rounded-full mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full"></div>
              <div class="flex items-center mt-4 space-x-3">
                <div>
                  <div class="h-2.5 bg-gray-200 rounded-full w-16 mb-2"></div>
                  <div class="flex justify-between w-full">
                    <div class="w-16 h-2 bg-gray-200 rounded-full"></div>
                    <div class="mx-auto"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--item 1-->
          <div
            class="w-40 sm:w-60 md:w-80 p-3 rounded-lg overflow-hidden shadow-md bg-white"
            data-aos="fade-up"
            data-aos-duration="1500"
            v-for="(item, index) in searchList.data"
            :key="index"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8 h-8 absolute top-2 text-primaryColorDark"
              v-if="!checkIfFavorite(item.i_id)"
              @click="updateUserFavorite('add', item.i_id)"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
              />
            </svg>

            <div v-else @click="updateUserFavorite('remove', item.i_id)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-8 h-8 absolute top-2 text-primaryColorDark"
              >
                <path
                  d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z"
                />
              </svg>
            </div>
            <a :href="'/products/' + item.i_id">
              <div class="flex w-40 h-36 md:w-72 md:h-68">
                <img
                  class="object-cover rounded-lg items-center"
                  :src="url + 'kcstore.online/public/storage/' + item.i_image"
                  alt="Category Image"
                />
              </div>
              <h3
                class="my_font text-base md:text-xl text-gray-800 text-left pt-2 overflow overflow-hidden line-clamp-1"
              >
                {{ item.i_name }}
              </h3>
              <span
                class="my_font text-3 md:text-3.5 font-sm text-gray-800 line-clamp-2"
              >
                {{
                  lang == "en"
                    ? item.i_desc_en
                    : lang == "ar"
                    ? item.i_desc_ar
                    : item.i_desc
                }}</span
              >
            </a>
            <div class="flex justify-between pt-4 md:pt-6 items-start">
              <div class="">
                <span
                  class="my_font text-base md:text-lg font-medium text-gray-800 text-left"
                >
                  {{
                    formatNumber(
                      item.i_discount == 0 ? item.i_price : item.i_discount
                    )
                  }}
                  {{ $t("iqd") }}
                </span>
                <br />
                <span
                  class="my_font line-through text-base font-medium text-gray-600 text-left"
                  v-if="item.i_discount != 0"
                >
                  {{ formatNumber(item.i_price) }}</span
                >
                <span
                  class="my_font text-xs font-medium text-gray-600 text-left"
                  v-else
                >
                  <!-- white space-->
                  &nbsp;
                </span>
              </div>
              <p
                class="my_font text-gray-700 text-lg font-medium inline-block"
                v-if="item.i_mawa == 0"
              >
                <span
                  class="my_font inline-block h-fit py-0.5 px-2 font-bold bg-primaryColorDark  text-white rounded-lg text-sm"
                  >{{
                    item.i_mawa == 0 ? $t("unavailable") : item.i_mawa
                  }}</span
                >
              </p>
              <button @click="addToCart(item.i_id, 1)" v-else>
                <img
                  src="/assets/icon/cart-plus.svg"
                  class="w-5"
                  alt=""
                  v-if="!checkIfItemAddedToCart(item.i_id)"
                />
                <font-awesome-icon
                  v-if="checkIfItemAddedToCart(item.i_id)"
                  :icon="['fas', 'check']"
                  size="xl"
                  class="text-primaryColor"
                />
              </button>
            </div>
          </div>
        </div>
        <!--Pagination-->
        <div
          class="flex items-center justify-between bg-white px-4 my-10 py-3 sm:px-6"
          v-if="searchList.data.length > 1"
        >
          <div class="sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p class="my_font text-lg text-gray-700">
                {{ $t("showing") }}
                <span class="my_font font-medium">{{ searchList.from }}</span>
                {{ $t("to") }}
                <span class="my_font font-medium">{{ searchList.to }}</span>
                {{ $t("of") }}
                <span class="my_font font-medium">{{ searchList.total }}</span>
                {{ $t("products") }}
              </p>
            </div>
            <div>
              <nav
                class="isolate inline-flex flex-wrap -space-x-px rounded-md shadow-sm"
                aria-label="Pagination"
              >
                <!--if rtl change rounded-l-md to rounded-r-md-->
                <div
                  class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-600 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0"
                  @click="changePage(1)"
                >
                  <!--if rtl add flip-horizontal to svg class-->
                  <font-awesome-icon
                    :icon="isRtl ? 'angle-double-right' : 'angle-double-left'"
                    size="base"
                    class="mx-1"
                  />
                </div>
                <div
                  class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-600 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0"
                  @click="changePage(page - 1)"
                >
                  <font-awesome-icon
                    :icon="isRtl ? 'angle-right' : 'angle-left'"
                    size="base"
                    class="mx-1"
                  />
                </div>
                <!-- Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300  focus:outline-offset-0" -->
                <div
                  aria-current="page"
                  :class="
                    'relative items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0 md:inline-flex ' +
                    (page == 1 ? ' bg-primaryColor text-white ' : '')
                  "
                  @click="changePage(1)"
                >
                  1
                </div>
                <div
                  :class="
                    'relative    items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0 md:inline-flex ' +
                    (page == 2 ? ' bg-primaryColor text-white ' : '')
                  "
                  @click="changePage(2)"
                  v-if="searchList.last_page >= 2"
                >
                  2
                </div>
                <div
                  :class="
                    'relative    items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0 md:inline-flex ' +
                    ((page >= 3 && page < searchList.last_page - 2) || page == 3
                      ? ' bg-primaryColor text-white '
                      : '')
                  "
                  @click="
                    changePage(
                      page >= 3 && page < searchList.last_page - 2 ? page : 3
                    )
                  "
                  v-if="searchList.last_page >= 3"
                >
                  {{ page >= 3 && page < searchList.last_page - 2 ? page : 3 }}
                </div>
                <div
                  :class="
                    'relative    items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0 md:inline-flex ' +
                    (page == 4 ? ' bg-primaryColor text-white ' : '')
                  "
                  @click="changePage(4)"
                  v-if="searchList.last_page < 7 && searchList.last_page >= 4"
                >
                  4
                </div>
                <div
                  :class="
                    'relative    items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0 md:inline-flex ' +
                    (page == 5 ? ' bg-primaryColor text-white ' : '')
                  "
                  @click="changePage(5)"
                  v-if="searchList.last_page < 7 && searchList.last_page >= 5"
                >
                  5
                </div>
                <div
                  :class="
                    'relative    items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0 md:inline-flex ' +
                    (page == 6 ? ' bg-primaryColor text-white ' : '')
                  "
                  @click="changePage(6)"
                  v-if="searchList.last_page < 7 && searchList.last_page >= 6"
                >
                  6
                </div>
                <div
                  :class="
                    'relative    items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0 md:inline-flex ' +
                    (page == 7 ? ' bg-primaryColor text-white ' : '')
                  "
                  @click="changePage(7)"
                  v-if="searchList.last_page < 7 && searchList.last_page > 6"
                >
                  7
                </div>
                <span
                  class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
                  v-if="searchList.last_page >= 7"
                  >...</span
                >
                <div
                  :class="
                    'relative    items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0 md:inline-flex ' +
                    (page == searchList.last_page - 2
                      ? ' bg-primaryColor text-white '
                      : '')
                  "
                  v-if="searchList.last_page >= 7"
                  @click="changePage(searchList.last_page - 2)"
                >
                  {{ searchList.last_page - 2 }}
                </div>
                <div
                  :class="
                    'relative    items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0 md:inline-flex ' +
                    (page == searchList.last_page - 1
                      ? ' bg-primaryColor text-white '
                      : '')
                  "
                  v-if="searchList.last_page >= 7"
                  @click="changePage(searchList.last_page - 1)"
                >
                  {{ searchList.last_page - 1 }}
                </div>
                <div
                  :class="
                    'relative    items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0 md:inline-flex ' +
                    (page == searchList.last_page
                      ? ' bg-primaryColor text-white '
                      : '')
                  "
                  v-if="searchList.last_page >= 7"
                  @click="changePage(searchList.last_page)"
                >
                  {{ searchList.last_page }}
                </div>
                <div
                  class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-600 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0"
                  @click="changePage(page + 1)"
                >
                  <font-awesome-icon
                    :icon="!isRtl ? 'angle-right' : 'angle-left'"
                    size="base"
                    class="mx-1"
                  />
                </div>
                <div
                  class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-600 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0"
                  @click="changePage(searchList.last_page)"
                >
                  <font-awesome-icon
                    :icon="!isRtl ? 'angle-double-right' : 'angle-double-left'"
                    size="base"
                    class="mx-1"
                  />
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div
          class="w-full items-center bg-white"
          v-if="searchList.data.length == 0 && isLoadSearch"
        >
          <center class="text-xl my_font">
            <img src="/assets/image/not_found.png" class="w-72" alt="" />
            {{ $t("no_product_found") }}
          </center>
        </div>
        <!--end of pagination-->
      </div>
    </div>
  </div>
  <LoginModal :show="isLoginShow" @close="closeModalLogin" />

  <Footer />
</template>

<script>
import Navbar from "../components/nav-bar.vue";
import Footer from "../components/footer-app.vue";
import LoginModal from "../components/login-modal.vue";

import { SERVER_URL } from "../../config.js";
import axios from "axios";
import { useMeta } from "vue-meta";

export default {
  name: "App",

  setup() {
    useMeta({
      title: "بەرهەمەکان",
      description:
        "پێستت بەشێکە لە تەندروستیت، تەندورستی پێستت بە ئێمە بسپێرە، بەهەبوونی زیاتر لە 2 ساڵ کارکردن لە بوارەکەدا و دابینکردنی بەناوبانگترین و چاکترین بەرهەمەکان لە وڵاتانی کۆریا و یابان و ولاتانی ئەوروپاوە، دەتوانین یارمەتیت بدەین بۆ بەدەستهێنانی پێستی دڵخواز و تەندروستی خۆت",
      //image
    });
  },
  components: {
    Navbar,
    Footer,
    LoginModal,
  },
  data() {
    return {
      isSearchOpen: false,
      search: "",
      url: SERVER_URL,
      categorys: [],
      loadingList: [1, 2, 3, 4],
      isRtlVar: false,
      searchList: {
        data: [],
      },
      isLoadSearch: false,
      currentOpenedCategory: "",
      currentOpenedSubCategory: "",
      page: 1,
      isSortByDropDownOpen: false,
      isShowSidebar: false,
      isLoginShow: false,
      sortList: [
        {
          name: "newest",
          value: "created_at",
          sort: "asc",
        },
        {
          name: "oldest",
          value: "created_at",
          sort: "desc",
        },
        {
          name: "price_low_to_high",
          value: "i_price",
          sort: "asc",
        },
        {
          name: "price_high_to_low",
          value: "i_price",
          sort: "desc",
        },
        {
          name: "name_a_to_z",
          value: "i_name",
          sort: "asc",
        },
        {
          name: "name_z_to_a",
          value: "i_name",
          sort: "desc",
        },
      ],
      currentSort: {
        name: "newest",
        value: "created_at",
        sort: "asc",
      },
      user: {},
      lang: localStorage.getItem("lang"),
    };
  },
  computed: {
    isRtl() {
      return (
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == "ku" ||
        //if null
        localStorage.getItem("lang") == null ||
        //if empty
        localStorage.getItem("lang") == ""
      );
    },
  },
  created() {
    var tempPage = JSON.parse(localStorage.getItem("page"));

    if (
      tempPage != null &&
      tempPage != undefined &&
      tempPage != "" &&
      tempPage != "undefined"
    ) {
      //check if tempPage is not null
      //check if date is same day
      if (new Date(tempPage.date).getDate() == new Date().getDate()) {
        this.page = tempPage.page;
      }
    }
    this.getNavBarCategorys();
    //get category from query
    if (this.$route.query.category) {
      this.currentOpenedCategory = this.$route.query.category;
      //get sub category from query if exist
      if (this.$route.query.sub) {
        this.currentOpenedSubCategory = this.$route.query.sub;
      }
    }
    //get search from query
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }
    this.getItemPaginations();
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  mounted() {},
  beforeRouteUpdate(to, from, next) {
    next();
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    getNavBarCategorys() {
      axios
        .post(SERVER_URL + "api/home/get-navbar-category", {
          lang: localStorage.getItem("lang"),
        })
        .then((response) => {
          // this.items = response.data;
          this.categorys = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchProduct() {
      this.page = 1;

      this.currentOpenedCategory = "";
      this.currentOpenedSubCategory = "";
      this.getItemPaginations();
    },
    sortBy(sort) {
      this.currentSort = sort;
      this.currentOpenedCategory = "";
      this.currentOpenedSubCategory = "";

      this.getItemPaginations();
    },
    changePage(page) {
      if (page > this.searchList.last_page) {
        this.page = this.searchList.last_page;
        localStorage.setItem(
          "page",
          JSON.stringify({
            page: this.page,
            date: Date.now(),
          })
        );
        return;
      }
      if (page < 1) {
        this.page = 1;
        this.getItemPaginations();
        localStorage.setItem(
          "page",
          JSON.stringify({
            page: this.page,
            date: Date.now(),
          })
        );
        return;
      }
      this.page = page;
      localStorage.setItem(
        "page",
        JSON.stringify({
          page: this.page,
          date: Date.now(),
        })
      );
      this.getItemPaginations();
    },
    getItemPaginations() {
      this.isLoadSearch = false;
      axios
        .post(SERVER_URL + "api/item/get-paginate", {
          ca_name: this.currentOpenedCategory,
          sc_id: this.currentOpenedSubCategory,
          search: this.search,
          sort: this.currentSort.value,
          sortType: this.currentSort.sort,
          page: this.page,
          lang: localStorage.getItem("lang"),
        })
        .then((response) => {
          // this.items = response.data;
          this.searchList = response.data;
          this.isLoadSearch = true;
        })
        .catch((error) => {
          console.log(error);
          this.isLoadSearch = true;
        });
    },
    changeCategory(category, subCategory, isSub) {
      this.page = 1;
      localStorage.setItem(
        "page",
        JSON.stringify({
          page: this.page,
          date: Date.now(),
        })
      );
      if (category == this.currentOpenedCategory && isSub == false) {
        this.currentOpenedCategory = "";
        this.currentOpenedSubCategory = "";
        this.$route.query.category = "";
        this.$route.query.sub = "";
        this.getItemPaginations();
        return;
      }
      this.currentOpenedCategory = category;
      this.currentOpenedSubCategory = subCategory;
      this.$route.query.category = category;
      this.$route.query.sub = subCategory;
      this.getItemPaginations();
      //update url without reload
    },
    getSubCatgeoryNameFromId(id) {
      if (this.categorys.length == 0) {
        return "";
      }
      let subCategory = this.categorys[this.currentOpenedCategory].find(
        (x) => x.sc_id == id
      );
      if (subCategory) {
        return subCategory.sc_name;
      }
      return "";
    },
    goToProduct(id) {
      //open link window in same tab

      window.open("/products/" + id);
    },
    //change isSortByDropDownOpen to true
    openSortByDropDown() {
      document.addEventListener("click", (e) => {
        if (e.target.closest(".sort-by-dropdown")) return;
        this.isSortByDropDownOpen = false;
      });

      this.isSortByDropDownOpen = !this.isSortByDropDownOpen;
      //close dropdwon when click outside
    },
    switchFilterSidebar() {
      document.addEventListener("click", (e) => {
        if (e.target.closest(".show-sidebar")) return;
        this.isShowSidebar = false;
      });

      this.isShowSidebar = !this.isShowSidebar;
      //close dropdwon when click outside
    },
    closeModalLogin() {
      this.isLoginShow = false;
    },
    addToCart(id, count) {
      if (this.user == null) {
        this.isLoginShow = true;
        return false;
      }
      //[{i_id:1,count:2},{i_id:2,count:1}] the format of the cartn as string

      //check if u_saba is null or empty
      if (this.user.u_sabata == null || this.user.u_sabata == "") {
        var arraySabata = [];
        var item2 = {
          i_id: id,
          count: count,
        };
        arraySabata.push(item2);
        const strList = JSON.stringify(arraySabata);
        this.user.u_sabata = strList;
      }
      //split the string into array by comma
      var cartTemp = this.user.u_sabata.toString();
      var cartArray = JSON.parse(cartTemp);

      var isItemExist = false;
      for (var j = 0; j < cartArray.length; j++) {
        if (cartArray[j].i_id == id) {
          isItemExist = true;
        }
      }
      if (!isItemExist) {
        var item1 = {
          i_id: id,
          count: count,
        };
        cartArray.push(item1);
      }
      //convert the array to string
      var cartString = JSON.stringify(cartArray);

      this.user.u_sabata = cartString;

      axios
        .post(SERVER_URL + "api/item/add-sabata", {
          items_array_string: cartString,
        })
        .then((response) => {
          //update the local storage
          localStorage.setItem("user", JSON.stringify(response.data));
          this.$store.state.user = JSON.parse(localStorage.getItem("user"));
          this.user = JSON.parse(localStorage.getItem("user"));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkIfItemAddedToCart(id) {
      if (this.user == null) return false;
      if (this.user.u_sabata == null || this.user.u_sabata == "") return false;
      //[{i_id:1,count:2},{i_id:2,count:1}] the format of the cartn as string
      var cartTemp = this.user.u_sabata.toString();
      var cartArray = JSON.parse(cartTemp);

      for (var i = 0; i < cartArray.length; i++) {
        if (cartArray[i].i_id == id) {
          return true;
        }
      }

      return false;
    },
    checkIfFavorite(id) {
      //loop inside user favorite split by comma
      //if item id exist return true
      if (this.user == null) return false;
      if (this.user.u_favorite == null || this.user.u_favorite == "")
        return false;
      var favoritesTemp = this.user.u_favorite.split(",");
      for (var i = 0; i < favoritesTemp.length; i++) {
        if (favoritesTemp[i] == id) {
          return true;
        }
      }

      return false;
    },
    updateUserFavorite(action, itemID) {
      if (this.user == null) {
        this.isLoginShow = true;
        return false;
      }

      if (action == "add") {
        if (this.user.u_favorite == null || this.user.u_favorite == "") {
          this.user.u_favorite = "," + itemID;
        } else this.user.u_favorite = this.user.u_favorite + "," + itemID;
      } else {
        //remove the item from the favorite
        var favorites = this.user.u_favorite;
        //split the string into array by comma
        var favoritesArray = favorites.split(",");

        //check if the id is in the array or not
        if (favoritesArray.includes(itemID.toString())) {
          //remove the item from the array
          var index = favoritesArray.indexOf(itemID.toString());
          if (index > -1) {
            favoritesArray.splice(index, 1);
          }
          //convert the array to string
          var favoritesString = favoritesArray.toString();
          //remove the first comma
          favoritesString = favoritesString.replace(/^,/, "");
          this.user.u_favorite = favoritesString;
        }
      }
      axios
        .post(SERVER_URL + "api/item/add-favorite", {
          i_id: itemID,
          action: action,
        })
        .then((response) => {
          //update the local storage
          localStorage.setItem("user", JSON.stringify(response.data));
          this.$store.state.user = JSON.parse(localStorage.getItem("user"));
          this.user = JSON.parse(localStorage.getItem("user"));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //
  },
};
</script>
<style scoped>
.-text {
  color: #2B4168;
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* Number of lines to display */
  line-clamp: 2; /* Number of lines to display */
}
.line-clamp-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* Number of lines to display */
  line-clamp: 1; /* Number of lines to display */
}
</style>
