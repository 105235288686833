<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
    <meta name="description" :content="description" />
  </metainfo>

  <Navbar />
  <div
    class="mx-8 grid grid-cols-1 lg:grid-cols-2 gap-x-8 mt-14 md:mt-32 gap-y-12 mb-16 justify-items-center"
    v-if="isLoading"
  >
    <div
      data-aos="fade-up"
      data-aos-duration="1500"
      v-for="(skelton, index) in [1, 2, 3, 4]"
      :key="index"
    >
      <div
        role="status"
        class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center"
      >
        <div
          class="flex items-center justify-center w-full h-48 bg-gray-400 rounded sm:w-96"
        >
          <svg
            class="w-64 h-12 bg-gray-300"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            fill="gray"
            viewBox="0 0 640 512"
          >
            <path
              d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"
            />
          </svg>
        </div>
        <div class="w-20"></div>
        <div class="w-full">
          <div class="h-2.5 bg-gray-300 rounded-full w-48 mb-4"></div>
          <div class="h-2 bg-gray-300 rounded-full max-w-[480px] mb-2.5"></div>
          <div class="h-2 bg-gray-300 rounded-full mb-2.5"></div>
          <div class="h-2 bg-gray-300 rounded-full max-w-[440px] mb-2.5"></div>
          <div class="h-2 bg-gray-300 rounded-full max-w-[460px] mb-2.5"></div>
          <div class="h-2 bg-gray-300 rounded-full max-w-[360px]"></div>
        </div>
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
  <div
    class="mx-8 grid grid-cols-1 lg:grid-cols-2 gap-x-8 mt-14 md:mt-32 gap-y-12 mb-16 justify-items-center"
    v-else
  >
    <div
      class="relative flex flex-col lg:flex-row lg:p-3 w-full max-w-[48rem] rounded-xl bg-white bg-clip-border text-gray-700 shadow-md"
      data-aos="fade-up"
      data-aos-duration="1500"
      v-for="(offer, index) in offers"
      :key="index"
    >
      <div
        class="relative m-0 w-full lg:w-2/5 shrink-0 overflow-hidden lg:shadow-md rounded-xl bg-white bg-clip-border text-gray-700"
      >
        <img
          :src="
            url +
            'kcstore.online/public/storage/' +
            (lang == 'en'
              ? offer.o_image_en
              : lang == 'ar'
              ? offer.o_image_ar
              : offer.o_image)
          "
          alt="image"
          class="h-full w-full object-cover"
        />
      </div>
      <div class="px-6 py-4 lg:py-2">
        <h4
          class="my_font mb-2 block text-2xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased"
        >
          {{
            lang == "en"
              ? offer.o_title_en
              : lang == "ar"
              ? offer.o_title_ar
              : offer.o_title
          }}
        </h4>
        <p
          class="my_font mb-8 block text-base font-normal h-32 leading-relaxed text-gray-700 overflow-hidden line-clamp-5"
        >
          {{
            lang == "en"
              ? offer.o_desc_en
              : lang == "ar"
              ? offer.o_desc_ar
              : offer.o_desc
          }}
        </p>
        <a class="inline-block" :href="'/offers/' + offer.o_id">
          <button
            class="my_font flex select-none items-center gap-2 rounded-lg py-2 px-5 text-center align-middle text-sm font-bold uppercase text-white bg-gradient-to-br from-gray-400 to-primaryColorDark"
            type="button"
          >
            {{ $t("offer.seeMore") }}

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              aria-hidden="true"
              :class="'h-4 w-4 ' + (isRtl ? 'transform rotate-180' : '')"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              ></path>
            </svg>
          </button>
        </a>
      </div>
    </div>
  </div>

  <Footer />
</template>

<script>
import Navbar from "../components/nav-bar.vue";
import Footer from "../components/footer-app.vue";

import { SERVER_URL } from "../../config.js";

import axios from "axios";
import { useMeta } from "vue-meta";

export default {
  name: "App",

  setup() {
    useMeta({
      title: "ئۆفەرەکان",
      description:
        "پێستت بەشێکە لە تەندروستیت، تەندورستی پێستت بە ئێمە بسپێرە، بەهەبوونی زیاتر لە 2 ساڵ کارکردن لە بوارەکەدا و دابینکردنی بەناوبانگترین و چاکترین بەرهەمەکان لە وڵاتانی کۆریا و یابان و ولاتانی ئەوروپاوە، دەتوانین یارمەتیت بدەین بۆ بەدەستهێنانی پێستی دڵخواز و تەندروستی خۆت",
      //image
    });
  },
  data() {
    return {
      url: SERVER_URL,
      offers: [],
      isLoading: true,
      lang: localStorage.getItem("lang"),
    };
  },
  created() {
    this.getOffers();
    window.scrollTo(0, 0);
  },
  components: {
    Navbar,
    Footer,
  },
  mounted() {},
  computed: {
    isRtl() {
      return (
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == "ku" ||
        //if null
        localStorage.getItem("lang") == null ||
        //if empty
        localStorage.getItem("lang") == ""
      );
    },
  },
  methods: {
    getOffers() {
      axios
        .post(SERVER_URL + "api/offer/get", {
          lang: this.lang,
        })
        .then((response) => {
          // this.items = response.data;
          this.offers = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.half-width {
  width: 50% !important;
}
</style>
