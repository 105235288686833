<template>
      <div class="relative flex justify-center items-center h-screen">
        <div class="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primaryColor"></div>
        <img src="/assets/image/loade.png"  class="rounded-full h-28 w-28">
    </div>
</template>
  
  <script>
  export default {
    computed: {
      loading() {
        return this.$store.state.loading; // Access the loading state from the Vuex store
      },
    },
  };
  </script>
  