<template>
  <Navbar />

  <div :class="'p-4 mt-20  ' + (isRtl ? 'sm:mr-64' : 'sm:ml-64')">
    <div
      class="w-full items-center bg-white mt-20"
      v-if="myCart.length == 0 && isDataLoaded"
    >
      <center class="text-xl my_font">
        <img src="/assets/image/not_found.png" class="w-72" alt="" />
        {{ $t("no_item_in_cart") }}
      </center>
    </div>
    <div
      class="lg:flex lg:mx-2 lg:space-x-3 xl:mx-6 xl:space-x-6"
      v-show="myCart.length > 0"
    >
      <!-- Left Side -->

      <div
        class="w-full lg:w-6/12 lg:mx-1 xl:mx-2 lg:mt-4 space-y-8 mb-20 lg:mb-0"
      >
        <!-- item Cards-->

        <!--single card-->
        <div
          class="flex containerrr w-98per rounded-xl bg-gray-50 shadow-md px-2 py-1 justify-between"
          v-for="(item, index) in myCart"
          :key="index"
        >
          <div class="flex items-center">
            <img
              :src="fileUrl + 'kcstore.online/public/storage/' + item.i_image"
              class="w-16 rounded-xl"
              alt="img"
            />
            <div class="w-1"></div>
            <div class="">
              <p
                class="my_font overflow-hidden truncate text-base sm:text-base md:text-base lg:text-base xl:text-base text-gray-700 font-medium my-auto mx-auto"
              >
                {{ item.i_name }}
              </p>
            </div>
          </div>

          <p class="text-base w-12 text-gray-700 my-auto mx-2">
            {{ formatNumber(getItemPrice(item)) }}
          </p>
          <div class="flex">
            <div
              class="h-10 text-sm bg-white my-auto flex items-center justify-between rounded-lg font-bold relatives"
            >
              <div
                id="minus"
                class="plus-minus px-3 sm:px-3 md:px-5 lg:px-3 xl:px-5"
                @click="changeAmount(item, 'minus')"
              >
                <svg
                  width="12"
                  height="4"
                  class="text-gray-600"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <defs>
                    <path
                      d="M11.357 3.332A.641.641 0 0 0 12 2.69V.643A.641.641 0 0 0 11.357 0H.643A.641.641 0 0 0 0 .643v2.046c0 .357.287.643.643.643h10.714Z"
                      id="a"
                    />
                  </defs>
                  <use
                    fill="currentColor"
                    fill-rule="nonzero"
                    xlink:href="#a"
                  />
                </svg>
              </div>

              <span id="amount" class="select-none">{{ item.count }}</span>
              <div
                id="plus"
                class="plus-minus px-3 sm:px-3 md:px-5 lg:px-3 xl:px-5"
                @click="changeAmount(item, 'plus')"
              >
                <svg
                  class="text-gray-600"
                  width="12"
                  height="12"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  id="plus"
                >
                  <defs>
                    <path
                      d="M12 7.023V4.977a.641.641 0 0 0-.643-.643h-3.69V.643A.641.641 0 0 0 7.022 0H4.977a.641.641 0 0 0-.643.643v3.69H.643A.641.641 0 0 0 0 4.978v2.046c0 .356.287.643.643.643h3.69v3.691c0 .356.288.643.644.643h2.046a.641.641 0 0 0 .643-.643v-3.69h3.691A.641.641 0 0 0 12 7.022Z"
                      id="b"
                    />
                  </defs>
                  <use
                    fill="currentColor"
                    fill-rule="nonzero"
                    xlink:href="#b"
                    id="plus"
                  />
                </svg>
              </div>
            </div>
            <div class="w-1 sm:w-2"></div>
            <p
              class="text-base w-10 mx-6 sm:w-12 text-gray-700 font-semibold my-auto mx-2 md:mx-4 lg:mx-2 xl:mx-4"
            >
              {{ formatNumber(getItemPrice(item) * item.count) }}
            </p>
          </div>
        </div>
        <!--single card-->
        <!--single card-->

        <!--total price-->
        <hr class="h-px text-gray-600 mx-1" />
        <div class="space-y-4">
          <div class="flex justify-between mx-3">
            <div class="flex">
              <p class="my_font text-lg text-gray-700 font-medium my-auto">
                {{ $t("item_count") }} :
              </p>
              <span
                class="my_font text-lg text-gray-700 font-semibold px-1"
                v-if="myCart != null"
                >{{ countProducts }}</span
              >
            </div>
            <div class="flex">
              <p class="my_font text-lg text-gray-700 font-medium my-auto">
                {{ $t("products_price") }} :
              </p>
              <span class="my_font text-lg text-gray-700 font-semibold px-1">{{
                formatNumber(priceAll())
              }}</span>
            </div>
          </div>

          <div class="flex justify-between mx-3" v-show="checkIFHasDiscount()">
            <p class="text-lg text-red-700 font-medium my-auto"></p>
            <div class="flex">
              <p class="my_font text-lg text-red-700 font-medium my-auto">
                {{ $t("total_discount") }} :
              </p>
              <span class="my_font text-lg text-red-700 font-semibold px-1">{{
                formatNumber(priceDiscount())
              }}</span>
            </div>
          </div>

          <div class="flex justify-between mx-3">
            <p class="text-lg text-gray-700 font-medium my-auto"></p>
            <div class="flex">
              <p class="my_font text-lg text-gray-700 font-medium my-auto">
                {{ $t("delivery_price") }} :
              </p>
              <span class="my_font text-lg text-gray-700 font-semibold px-1">{{
                getDeliveryPrice
              }}</span>
            </div>
          </div>
          <hr class="h-px text-gray-600 mx-1" />
          <div class="flex justify-between mx-3">
            <p class="my_font text-lg text-gray-700 font-medium my-auto">
              {{ $t("total_price") }}
            </p>
            <p class="my_font text-lg text-gray-800 font-semibold my-auto">
              {{
                formatNumber(priceAll() - priceDiscount() + getDeliveryPrice)
              }}
            </p>
          </div>
        </div>
      </div>

      <!-- Right Side -->
      <div class="w-full lg:w-6/12 lg:mx-2 sm:mt-8 md:mt-4">
        <!--order information-->
        <div class="lg:mx-4">
          <form>
            <div class="mb-6">
              <label
                for="dropdown"
                class="my_font block mb-2 text-base my_font text-gray-900"
                >{{ $t("city") }}</label
              >
              <select
                id="countries"
                class="my_font bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
                v-model="o_city"
              >
                <option value="slemany" selected class="my_font">
                  {{ $t("sulaymaniyah") }}
                </option>
                <option value="sulaymaniyah_out" class="my_font">
                  {{ $t("sulaymaniyah_out") }}
                </option>
                <option value="hawler" class="my_font">
                  {{ $t("erbil") }}
                </option>
                <option value="dhok" class="my_font">{{ $t("duhok") }}</option>
                <option value="kirkuk" class="my_font">
                  {{ $t("kirkuk") }}
                </option>
                <option value="halabja" class="my_font">
                  {{ $t("halabja") }}
                </option>
                <option value="baghdad" class="my_font">
                  {{ $t("baghdad") }}
                </option>
                <option value="anbar" class="my_font">{{ $t("anbar") }}</option>
                <option value="babl" class="my_font">{{ $t("babil") }}</option>
                <option value="basra" class="my_font">{{ $t("basra") }}</option>
                <option value="diwaniyah" class="my_font">
                  {{ $t("diwaniyah") }}
                </option>
                <option value="ziqar" class="my_font">{{ $t("ziqar") }}</option>
                <option value="salah_al_din" class="my_font">
                  {{ $t("salah_al_din") }}
                </option>
                <option value="qadisiyah" class="my_font">
                  {{ $t("qadisiyah") }}
                </option>
                <option value="karbala" class="my_font">
                  {{ $t("karbala") }}
                </option>
                <option value="muthanna" class="my_font">
                  {{ $t("muthanna") }}
                </option>
                <option value="missan" class="my_font">
                  {{ $t("missan") }}
                </option>
                <option value="najaf" class="my_font">{{ $t("najaf") }}</option>
                <option value="mosul" class="my_font">
                  {{ $t("mosul") }}
                </option>
                <option value="wasit" class="my_font">{{ $t("wasit") }}</option>
              </select>
            </div>
            <div class="mb-6">
              <label
                for="Address"
                class="my_font block mb-2 text-base my_font text-gray-900"
                >{{ $t("address") }}</label
              >
              <input
                type="text"
                id="Address"
                class="my_font bg-gray-50 border border-gray-300 text-gray-900 placeholder-gray-700 font-medium text-sm rounded-lg focus:ring-primaryColor focus:border-primaryColor block w-full p-2.5"
                v-model="o_address"
                required
              />
            </div>
            <div class="mb-6">
              <label
                for=""
                class="my_font block mb-2 text-base font-medium text-gray-900"
                >{{ $t("note") }}</label
              >
              <textarea
                id="message"
                rows="4"
                class="block p-2.5 w-full text-sm text-gray-900 placeholder-gray-700 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primaryColor focus:border-primaryColor"
                v-model="o_note"
              ></textarea>
            </div>
            <div class="mb-6 w-full flex flex-col" v-if="!is_any_item_discount">
              <div class="flex-1" v-if="!o_useMyAccountDiscount">
                <label
                  for="promoCode"
                  class="my_font block mb-2 text-base font-medium text-gray-900"
                  >{{ $t("promo_code") }}</label
                >
                <div
                  class="rounded-lg border bg-gray-50 border-gray-300 flex items-center justify-between"
                >
                  <input
                    class="my_font text-gray-900 placeholder:text-3 placeholder-gray-700 bg-gray-50 text-base rounded-lg focus:ring-primaryColor focus:outline-none focus:border-primaryColor w-full p-2 sm:p-2.5"
                    :placeholder="$t('promo_code')"
                    v-model="promo_code_txt"
                  />
                  <button
                    type="button"
                    class="my_font py-3 sm:py-2.5 px-2 md:px-5 lg:px-3 xl:px-5 bg-primaryColor text-white disabled:opacity-50 inline-flex text-3 sm:text-4 items-center rounded-lg focus:outline-none"
                    @click="applyPromoCode"
                  >
                    <span class="my_font" v-if="!promoCheckLoading">
                      {{ $t("apply") }}</span
                    >
                    <div role="status" v-else>
                      <svg
                        aria-hidden="true"
                        class="w-6 h-6 mr-2 text-gray-200 animate-spin fill-primaryColor"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
              <div class="mx-2 lg:mx-5"></div>
              <div class="flex-1 mt-10" v-if="!checkIfHastPromoCode()">
                <div class="hs-tooltip flex items-center">
                  <!--in rtl change (checked:before:translate-x-full to checked:before:-translate-x-full)-->
                  <input
                    type="checkbox"
                    id="hs-tooltip-example"
                    v-model="o_useMyAccountDiscount"
                    @change="useMyAccountDiscountFunc"
                    :class="
                      (isRtl
                        ? 'checked:before:-translate-x-full'
                        : 'checked:before:translate-x-full') +
                      ' hs-tooltip-toggle relative shrink-0 w-[3.25rem] h-7 bg-gray-100 checked:bg-none checked:bg-blue-200 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 ring-1 ring-transparent focus:ring-0 ring-offset-white focus:outline-none appearance-none before:inline-block before:w-6 before:h-6 before:bg-white checked:before:bg-primaryColor before:translate-x-0  before:shadow before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 '
                    "
                  />
                  <div class="mx-2"></div>
                  <label
                    for="hs-tooltip-example"
                    class="my_font text-base sm:text-sm text-gray-900"
                    >{{ $t("use_my_account_discount") }}
                    <span class="my_font text-lg font-medium text-gray-900">{{
                      getMyAccountDiscount()
                    }}</span></label
                  >

                  <div
                    class="my_font hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded-md shadow-sm"
                    role="tooltip"
                  >
                    {{ $t("use_my_account_discount") }}
                    {{ getMyAccountDiscount() }}
                  </div>
                </div>
              </div>
            </div>
            <button
              class="my_font curser-pointer text-white bg-gradient-to-br from-primaryColorDark to-blue-300 focus:outline-none font-medium rounded-lg text-lg px-5 py-3 flex justify-center w-1/2 mx-auto"
              @click="sendOrder"
              type="button"
            >
              <!--in rtl change mr to ml and ml to mr-->
              <svg
                aria-hidden="true"
                class="w-5 h-5 mx-2 my-auto"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                v-if="!sendOrderLoading"
              >
                <path
                  d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
                ></path>
              </svg>
              <span v-if="!sendOrderLoading" class="my_font">
                {{ $t("confirm_order") }}
              </span>
              <div role="status" v-else>
                <svg
                  aria-hidden="true"
                  class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-primaryColor"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "./components/nav-bar.vue";

import "swiper/swiper-bundle.min.css";
import { SERVER_URL } from "../../config.js";
import { SERVER_FILE_URL } from "../../config.js";

import axios from "axios";
//import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  name: "App",

  components: {
    Navbar,
  },
  data() {
    return {
      url: SERVER_URL,
      isDataLoaded: false,
      fileUrl: SERVER_FILE_URL,
      myCart: [],
      user: {},
      o_city: "",
      o_address: "",
      o_note: "",
      o_useMyAccountDiscount: false,
      promo_code: {},
      promoCheckLoading: false,
      promo_code_txt: "",
      sendOrderLoading: false,
      is_any_item_discount: false,
    };
  },
  computed: {
    isRtl() {
      return (
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == "ku" ||
        //if null
        localStorage.getItem("lang") == null ||
        //if empty
        localStorage.getItem("lang") == ""
      );
    },
    getDeliveryPrice() {
      var deliveryPrice = 0;
      if (this.o_city == "slemany") {
        deliveryPrice = 2000;
      }
      //else if o_city in ['hawler', 'dhok', 'kirkuk', 'halabja']
      else if (
        this.o_city == "hawler" ||
        this.o_city == "dhok" ||
        this.o_city == "kirkuk" ||
        this.o_city == "halabja" ||
        this.o_city == "sulaymaniyah_out"
      ) {
        deliveryPrice = 3000;
      } else {
        deliveryPrice = 5000;
      }

      var count = 0;
      if (this.myCart.length > 0) {
        this.myCart.forEach((item) => {
          if (item.i_is_delivery_free == 1) {
            if (
              item.count * this.getItemPrice(item) >= 12000 &&
              item.count >= 2
            ) {
              count += 2;
            } else if (
              item.count * this.getItemPrice(item) >= 6000 &&
              item.count >= 1
            ) {
              count += 1;
            } else if (this.getItemPrice(item) >= 6000) {
              count += 1;
            }
          }
        });
      }
      if (count >= 2) {
        if (
          this.o_city == "slemany" ||
          this.o_city == "hawler" ||
          this.o_city == "dhok" ||
          this.o_city == "kirkuk" ||
          this.o_city == "halabja" ||
          this.o_city == "sulaymaniyah_out"
        ) {
          deliveryPrice = 0;

          //else if o_city in ['hawler', 'dhok', 'kirkuk', 'halabja']
        } else {
          deliveryPrice = 4000;
        }
      }

      return deliveryPrice;
    },
    countProducts() {
      var count = 0;
      if (this.myCart.length > 0) {
        this.myCart.forEach((item) => {
          //

          count += parseInt(item.count);
        });
      }
      return count;
    },
  },
  created() {
    //get user from local storage
    this.user = JSON.parse(localStorage.getItem("user"));
    this.o_city = this.user.u_city;
    this.o_address = this.user.u_address;
    this.getMyCart();
  },
  methods: {
    formatNumber(num) {
      if (num == null) return 0;
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    getItemPrice(item) {
      console.log(item);
      if (parseInt(item.i_discount) > 0) {
        //agar item discount habe awa natwanet discounty tr bakar bhenet
        this.is_any_item_discount = true;
        return parseInt(item.i_discount);
      }
      return item.i_price;
    },
    getMyCart() {
      axios
        .post(SERVER_URL + "api/item/get-sabata")
        .then((response) => {
          this.myCart = response.data;
          this.isDataLoaded = true;
        })
        .catch((error) => {
          console.log(error);
          this.isDataLoaded = true;
        });
    },
    applyPromoCode() {
      if (this.promo_code_txt == "" || this.promo_code_txt == null) {
        return;
      }
      if (this.promoCheckLoading) {
        return;
      }
      this.promoCheckLoading = true;
      axios
        .post(SERVER_URL + "api/promo-code/check", {
          pc_code: this.promo_code_txt,
        })
        .then((response) => {
          this.promo_code = response.data;
          this.promoCheckLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.promoCheckLoading = false;
          if (error.response.status == 404) {
            //data.message

            toast.error(this.$t(error.response.data.message), {
              position: "bottom-center",
              duration: 5000,
            });
          }
        });
    },
    sendOrder() {
      if (this.o_city == "" || this.o_city == null) {
        toast.error(this.$t("please_enter_your_city"), {
          position: "bottom-center",
          duration: 5000,
        });
        return;
      }
      if (this.o_address == "" || this.o_address == null) {
        toast.error(this.$t("please_enter_your_address"), {
          position: "bottom-center",
          duration: 5000,
        });
        return;
      }
      if (this.sendOrderLoading) {
        return;
      }
      if (!confirm(this.$t("are_you_sure_you_want_to_make_order"))) return;
      this.sendOrderLoading = true;
      var data = {
        o_city: this.o_city,
        o_address: this.o_address,
        o_note: this.o_note,
        is_use_my_discount: this.o_useMyAccountDiscount,
        promo_code: this.promo_code_txt,
        items: this.myCart,
      };

      axios
        .post(SERVER_URL + "api/order/store", data)
        .then((response) => {
          if (response.data.message == "success") {
            toast.success(this.$t("order_sent_successfully_we"), {
              position: "bottom-center",
              duration: 5000,
            });
            window.location.href = "/profile/orders/" + response.data.order_id;
          }
          this.sendOrderLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.sendOrderLoading = false;
          if (error.response.status == 404) {
            //data.message

            if (
              error.response.data.message ==
              "not_enough_item_available_in_store"
            ) {
              toast.error(
                this.$t(error.response.data.message) +
                  error.response.data.i_name,
                {
                  position: "bottom-center",
                  duration: 5000,
                }
              );
            } else {
              toast.error(this.$t(error.response.data.message), {
                position: "bottom-center",
                duration: 5000,
              });
            }
          }
        });
    },
    priceAll() {
      let price = 0;
      this.myCart.forEach((item) => {
        if (item.i_discount > 0) {
          price += item.i_discount * item.count;
        } else {
          price += item.i_price * item.count;
        }
      });

      return price;
    },
    priceDiscount() {
      var price = this.priceAll();
      if (this.o_useMyAccountDiscount && this.user.u_discount > 0) {
        price = price * this.user.u_discount;
      } else if (Object.keys(this.promo_code).length > 0) {
        price = price * this.promo_code.pc_amount;
      } else {
        return 0;
      }
      return price;
    },
    checkIfHastPromoCode() {
      if (Object.keys(this.promo_code).length > 0) {
        return true;
      }
      return false;
    },
    checkIFHasDiscount() {
      if (this.o_useMyAccountDiscount && this.user.u_discount > 0) {
        return true;
      }
      //if this.promo_code not empty
      if (Object.keys(this.promo_code).length > 0) {
        return true;
      }
      return false;
    },
    getMyAccountDiscount() {
      //0.05 => 5% convert to %05
      return this.user.u_discount * 100 + "%";
    },
    useMyAccountDiscountFunc() {
      this.promo_code = {};
      this.promo_code_txt = "";
    },
    changeAmount(item, type) {
      if (type == "plus") {
        if (item.i_is_show_mawa == 1) {
          if (item.i_mawa == item.count) {
            alert(this.$t("you_cant_order_more_than") + " " + item.i_mawa);
            return;
          }
        }
        item.count++;
      } else {
        if (item.count > 1) {
          item.count--;
        } else if (item.count == 1) {
          //remove item from myCart
          this.myCart = this.myCart.filter((i) => i.i_id != item.i_id);
        }
      }
      //create a new array i_item and count of myCart
      let newMyCart = [];
      this.myCart.forEach((item) => {
        newMyCart.push({
          i_id: item.i_id,
          count: item.count,
        });
      });
      var cartString = JSON.stringify(newMyCart);

      this.user.u_sabata = cartString;

      axios
        .post(SERVER_URL + "api/item/add-sabata", {
          items_array_string: cartString,
        })
        .then((response) => {
          //update the local storage
          localStorage.setItem("user", JSON.stringify(response.data));
          this.$store.state.user = JSON.parse(localStorage.getItem("user"));
          this.user = JSON.parse(localStorage.getItem("user"));
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.containerrr {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}
</style>
