<template>
  <footer id="footer" class="bg-gray-900 pt-16">
    <div class="mx-8 md:mx-16">
      <div class="flex flex-wrap">
        <div
          class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 wow fadeInUp"
          data-wow-delay="0.2s"
        >
          <div class="mx-3 mb-8">
            <div class="footer-logo mb-3">
              <img src="/assets/image/kcStore.png" alt="" class="w-30" />
            </div>
            <p class="text-gray-300 text-base my_font">
              {{ $t("about_detail") }}
            </p>
          </div>
        </div>
        <div
          class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 wow fadeInUp"
          data-wow-delay="0.4s"
        >
          <div class="mx-8 mb-8">
            <h3 class="my_font font-bold text-xl text-white mb-5">
              {{ $t("links") }}
            </h3>
            <ul>
              <li>
                <a href="/" class="my_font footer-links text-lg">{{
                  $t("home")
                }}</a>
              </li>
              <li>
                <a href="/products" class="my_font footer-links text-lg">{{
                  $t("product")
                }}</a>
              </li>
              <li>
                <a href="/offers" class="my_font footer-links text-lg">{{
                  $t("offers")
                }}</a>
              </li>
              <li>
                <a href="/about" class="my_font footer-links text-lg">{{
                  $t("about")
                }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 wow fadeInUp"
          data-wow-delay="0.6s"
        >
          <div class="mx-3 mb-8">
            <h3 class="my_font font-bold text-xl text-white mb-5">
              {{ $t("account") }}
            </h3>
            <ul>
              <li>
                <a
                  href="/signup"
                  class="my_font text-lg footer-links"
                  v-show="!isSignedIn"
                >
                  {{ $t("sign_up") }}</a
                >
              </li>
              <li>
                <a
                  href="/signin"
                  class="my_font text-lg footer-links"
                  v-show="!isSignedIn"
                >
                  {{ $t("sign_in") }}</a
                >
              </li>
              <li>
                <a
                  href="/profile"
                  class="my_font text-lg footer-links"
                  v-show="isSignedIn"
                >
                  {{ $t("my_account") }}</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div
          class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 wow fadeInUp"
          data-wow-delay="0.8s"
        >
          <div class="mx-3 mb-8">
            <h3 class="my_font font-bold text-xl text-white mb-5">
              {{ $t("contact") }}
            </h3>

            <ul class="social-icons flex justify-start flex-col gap-4">
              <li class="mx-2">
                <div class="flex">
                  <a
                    href="https://www.facebook.com/koreancosmeticstore2021"
                    class="footer-icon bg-gradient-to-br from-gray-700 to-primaryColorDark"
                  >
                    <i class="fa-brands fa-facebook"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/koreancosmeticstore2021"
                    class="my_font text-base flex justify-center text-4 font-bold text-white items-center px-1"
                    >{{ $t("facebook") }}</a
                  >
                </div>
              </li>
              <li class="mx-2">
                <div class="flex">
                  <a
                    href="https://www.instagram.com/korean_c_store_/"
                    class="footer-icon bg-gradient-to-br from-gray-700 to-primaryColorDark"
                  >
                    <i class="fa-brands fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/korean_c_store_/"
                    class="my_font text-base flex justify-center text-4 font-bold text-white items-center px-1"
                    >{{ $t("instagram") }}</a
                  >
                </div>
              </li>

              <li class="mx-2">
                <div class="flex">
                  <a
                    href="tel:+9647512308652"
                    class="footer-icon bg-gradient-to-br from-gray-700 to-primaryColorDark"
                  >
                    <i class="fa-solid fa-phone"></i>
                  </a>

                  <a
                    href="tel:+9647512308652"
                    class="my_font text-base flex justify-center text-4 font-bold text-white items-center px-1"
                    >0751-230-8652</a
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="curser-pointer w-full text-center pt-6">
          <p class="my_font text-white">
            {{ $t("developed_by") }}
            <a class="text-purple-500" href="https://wise-devs.com/">
              WiseDevs
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>

   <a
    href="#"
    class="back-to-top w-10 h-10 fixed bottom-0 right-0 mb-5 mr-5 flex items-center justify-center rounded-full tracking-tight text-white bg-gradient-to-br from-primaryColor to-primaryColorDark text-lg z-20 duration-300 hover:bg-blue-400"
  >
    <i class="fa-solid fa-chevron-up"></i>
  </a>

</template>

<script>
export default {
  computed: {
    isSignedIn() {
      const token = localStorage.getItem("token");
      if (!token) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style></style>
