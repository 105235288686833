<template>
  <Navbar />

  <div :class="'p-4 mt-20  ' + (isRtl ? 'sm:mr-64' : 'sm:ml-64')">
    <div
      class="w-full items-center bg-white mt-20"
      v-if="favorites.length == 0 && isDataLoaded"
    >
      <center class="text-xl my_font">
        <img src="/assets/image/not_found.png" class="w-72" alt="" />
        {{ $t("no_product_found") }}
      </center>
    </div>
    <div class="px-6 mx-auto">
      <div
        class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 justify-items-center items-center my-5 p-5"
      >
        <div
          :class="
            'w-80 h-100 p-3 rounded-lg rounded shadow animate-pulse bg-white border border-gray-200 md:p-6 ' +
            (isLoadSearch == false && searchList.data.length == 0
              ? ''
              : ' hidden')
          "
          data-aos="fade-up"
          data-aos-duration="1500"
          v-for="(item, index) in favorites"
          :key="index"
        >
          <div role="status">
            <div
              class="flex items-center justify-center h-64 mb-4 bg-gray-300 rounded"
            >
              <svg
                class="w-10 h-10 text-gray-200"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 16 20"
              >
                <path
                  d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z"
                />
                <path
                  d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"
                />
              </svg>
            </div>
            <div class="flex items-center mt-4 space-x-3">
              <div>
                <div class="h-2.5 bg-gray-200 rounded-full w-32 mb-2"></div>
                <div class="w-48 h-2 bg-gray-200 rounded-full"></div>
              </div>
            </div>
            <div class="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
            <div class="h-2 bg-gray-200 rounded-full mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full"></div>
            <div class="flex items-center mt-4 space-x-3">
              <div>
                <div class="h-2.5 bg-gray-200 rounded-full w-16 mb-2"></div>
                <div class="flex justify-between w-full">
                  <div class="w-16 h-2 bg-gray-200 rounded-full"></div>
                  <div class="mx-auto"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="h-100 p-3 rounded-lg overflow-hidden shadow-md bg-white swiper-slide"
          v-for="(item1, index) in favorites"
          :key="index"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-8 h-8 absolute top-2 text-primaryColorDark"
            v-if="!checkIfFavorite(item1.i_id)"
            @click="updateUserFavoriteRelated('add', item1.i_id)"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
            />
          </svg>

          <div v-else @click="updateUserFavoriteRelated('remove', item1.i_id)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-8 h-8 absolute top-2 text-primaryColorDark"
            >
              <path
                d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z"
              />
            </svg>
          </div>
          <a :href="'/products/' + item1.i_id">
            <div class="flex w-64 h-56 md:w-72 md:h-68 mb-8 md:mb-0 mx-auto">
              <img
                class="object-cover rounded-lg items-center"
                :src="url + 'kcstore.online/public/storage/' + item1.i_image"
                alt=" Image"
              />
            </div>
            <h3 class="my_font text-xl text-gray-800 text-left pt-2">
              {{ item1.i_name }}
            </h3>
            <div class="my_font text-3.5 font-sm text-gray-800 line-clamp-2">
              {{
                lang == "en"
                  ? item1.i_desc_en
                  : lang == "ar"
                  ? item1.i_desc_ar
                  : item1.i_desc
              }}
            </div>
          </a>
          <div class="flex justify-between pt-6 items-start">
            <div class="">
              <span class="my_font text-lg font-medium text-gray-800 text-left">
                {{
                  formatNumber(
                    item1.i_discount == 0 ? item1.i_price : item1.i_discount
                  )
                }}
                {{ $t("iqd") }}
              </span>
              <br />
              <span
                class="my_font line-through text-base font-medium text-gray-600 text-left"
                v-if="item1.i_discount != 0"
              >
                {{ formatNumber(item1.i_price) }}</span
              >
              <span
                class="my_font text-xs font-medium text-gray-600 text-left"
                v-else
              >
                <!-- white space-->
                &nbsp;
              </span>
            </div>

            <button @click="addToCart(item1.i_id, 1)">
              <img
                src="/assets/icon/cart-plus.svg"
                class="w-5"
                alt=""
                v-if="!checkIfItemAddedToCart(item1.i_id)"
              />
              <font-awesome-icon
                v-if="checkIfItemAddedToCart(item1.i_id)"
                :icon="['fas', 'check']"
                size="xl"
                class="text-primaryColor"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "./components/nav-bar.vue";

import "swiper/swiper-bundle.min.css";
import { SERVER_URL } from "../../config.js";
import axios from "axios";
//import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "App",

  components: {
    Navbar,
  },
  data() {
    return {
      url: SERVER_URL,
      isDataLoaded: false,
      favorites: [],
      user: {},
      lang: localStorage.getItem("lang"),
    };
  },
  computed: {
    isRtl() {
      return (
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == "ku" ||
        //if null
        localStorage.getItem("lang") == null ||
        //if empty
        localStorage.getItem("lang") == ""
      );
    },
  },
  created() {
    this.getFavorites();
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    getFavorites() {
      axios
        .post(SERVER_URL + "api/user/get-favorites", {
          lang: localStorage.getItem("lang"),
        })
        .then((response) => {
          this.favorites = response.data;
          this.isDataLoaded = true;
        })
        .catch((error) => {
          console.log(error);
          this.isDataLoaded = true;
        });
    },
    checkIfFavorite(id) {
      //loop inside user favorite split by comma
      //if item id exist return true

      if (this.user == null) return false;
      if (this.user.u_favorite == null || this.user.u_favorite == "")
        return false;
      var favoritesTemp = this.user.u_favorite.split(",");
      for (var i = 0; i < favoritesTemp.length; i++) {
        if (favoritesTemp[i] == id) {
          return true;
        }
      }

      return false;
    },
    updateUserFavoriteRelated(action, itemID) {
      if (this.user == null) {
        this.isLoginShow = true;
        return false;
      }

      if (action == "add") {
        if (this.user.u_favorite == null || this.user.u_favorite == "") {
          this.user.u_favorite = "," + itemID;
        } else this.user.u_favorite = this.user.u_favorite + "," + itemID;
      } else {
        //remove the item from the favorite
        var favorites = this.user.u_favorite;
        //split the string into array by comma
        var favoritesArray = favorites.split(",");

        //check if the id is in the array or not
        if (favoritesArray.includes(itemID.toString())) {
          //remove the item from the array
          var index = favoritesArray.indexOf(itemID.toString());
          if (index > -1) {
            favoritesArray.splice(index, 1);
          }
          //convert the array to string
          var favoritesString = favoritesArray.toString();
          //remove the first comma
          favoritesString = favoritesString.replace(/^,/, "");
          this.user.u_favorite = favoritesString;
        }
      }
      axios
        .post(SERVER_URL + "api/item/add-favorite", {
          i_id: itemID,
          action: action,
        })
        .then((response) => {
          //update the local storage
          localStorage.setItem("user", JSON.stringify(response.data));
          this.$store.state.user = JSON.parse(localStorage.getItem("user"));
          this.user = JSON.parse(localStorage.getItem("user"));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addToCart(id, count) {
      //[{i_id:1,count:2},{i_id:2,count:1}] the format of the cartn as string

      //check if u_saba is null or empty
      if (this.user.u_sabata == null || this.user.u_sabata == "") {
        var arraySabata = [];
        var item2 = {
          i_id: id,
          count: count,
        };
        arraySabata.push(item2);
        const strList = JSON.stringify(arraySabata);
        this.user.u_sabata = strList;
      }
      //split the string into array by comma
      var cartTemp = this.user.u_sabata.toString();
      var cartArray = JSON.parse(cartTemp);

      var isItemExist = false;
      for (var j = 0; j < cartArray.length; j++) {
        if (cartArray[j].i_id == id) {
          isItemExist = true;
        }
      }
      if (!isItemExist) {
        var item1 = {
          i_id: id,
          count: count,
        };
        cartArray.push(item1);
      }
      //convert the array to string
      var cartString = JSON.stringify(cartArray);

      this.user.u_sabata = cartString;

      axios
        .post(SERVER_URL + "api/item/add-sabata", {
          items_array_string: cartString,
        })
        .then((response) => {
          //update the local storage
          localStorage.setItem("user", JSON.stringify(response.data));
          this.$store.state.user = JSON.parse(localStorage.getItem("user"));
          this.user = JSON.parse(localStorage.getItem("user"));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkIfItemAddedToCart(id) {
      if (this.user.u_sabata == null || this.user.u_sabata == "") return false;
      //[{i_id:1,count:2},{i_id:2,count:1}] the format of the cartn as string
      var cartTemp = this.user.u_sabata.toString();
      var cartArray = JSON.parse(cartTemp);

      for (var i = 0; i < cartArray.length; i++) {
        if (cartArray[i].i_id == id) {
          return true;
        }
      }

      return false;
    },
  },
};
</script>

<style></style>
