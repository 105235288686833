import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import "aos/dist/aos.css";
import "./assets/styles.css";
import "@fortawesome/fontawesome-free/css/all.css";
import Firebase from "./firebase/firebase_config.js";
//import HelloWorld from "./components/HelloWorld.vue";
import Home from "./components/Home.vue";
import MyProfile from "./components/profile/MyProfile.vue";
import MyFavorites from "./components/profile/MyFavorites.vue";
import SettingsPage from "./components/profile/Settings-Page.vue";
import MyOrders from "./components/profile/MyOrders.vue";
import MyOrderDetail from "./components/profile/MyOrderDetail.vue";
import MyCart from "./components/profile/MyCart.vue";
import About from "./components/About.vue";
import Offers from "./components/offer/Offers.vue";
import OfferDetail from "./components/offer/OfferDetail.vue";
import SignUp from "./components/auth/SignUp.vue";
import SignIn from "./components/auth/SignIn.vue";
import Products from "./components/product/Products.vue";
import ProductDetail from "./components/product/ProductDetail.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { createStore } from "vuex"; // Import createStore from Vuex
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
import axios from "axios";
import { SERVER_URL } from "./config.js";
import moment from "moment";
import "moment-timezone";
import { createMetaManager } from "vue-meta";

library.add(fas, fab);
//init vue-grid-layout

//vue components FontAwesomeIcon globally
//user Firebase just for error handling
// eslint-disable-next-line no-unused-vars
const firebase = Firebase;
//console.log(firebase);
var language = localStorage.getItem("lang");
if (language == null) {
  language = "ku";
}
const i18n = createI18n({
  locale: language, // default language
  //change direction of the app

  messages: {
    en: require("../locales/en.json"), // import language file
    // add other languages here
    ar: require("../locales/ar.json"),
    ku: require("../locales/ku.json"),
  },
});
const isAuthenticated = async () => {
  //check if user is authenticated
  const token = localStorage.getItem("token");
  //if token not exist

  if (!token) {
    return false;
  }
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  var isAuth = false;
  await axios
    .post(SERVER_URL + "api/app-user/get", {
      firebase_token: localStorage.getItem("firebase_token"),
    })
    .then((response) => {
      //check if response has success key and it's true

      if (response.data.success == true) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        // console.log(response.data.data);
        isAuth = true;
      } else {
        isAuth = false;
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
    })
    // eslint-disable-next-line no-unused-vars
    .catch((error) => {
      isAuth = false;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    });
  return isAuth;
};
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  { path: "/offers", name: "Offers", component: Offers },
  {
    path: "/offers/:id",
    name: "OfferDetail",
    component: OfferDetail,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
    beforeEnter: async (to, from, next) => {
      var isAuth = await isAuthenticated();

      if (isAuth == false) {
        next();
      } else {
        window.location.href = "/profile";
      }
    },
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignIn,
    beforeEnter: async (to, from, next) => {
      var isAuth = await isAuthenticated();

      if (isAuth == false) {
        next();
      } else {
        window.location.href = "/profile";
      }
    },
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
    beforeEnter: async (to, from, next) => {
      await isAuthenticated();

      next();
    },
  },
  {
    path: "/products/:id",
    name: "ProductDetail",
    component: ProductDetail,
    beforeEnter: async (to, from, next) => {
      await isAuthenticated();

      next();
    },
  },
  {
    path: "/profile",
    name: "MyProfile",
    component: MyProfile,
    beforeEnter: async (to, from, next) => {
      var isAuth = await isAuthenticated();

      if (isAuth == true) {
        next();
      } else {
        window.location.href = "/signin";
      }
    },
  },
  {
    path: "/profile/favorites",
    name: "MyFavorites",
    component: MyFavorites,
    beforeEnter: async (to, from, next) => {
      var isAuth = await isAuthenticated();

      if (isAuth == true) {
        next();
      } else {
        window.location.href = "/signin";
      }
    },
  },
  {
    path: "/profile/settings",
    name: "Settings",
    component: SettingsPage,
    beforeEnter: async (to, from, next) => {
      var isAuth = await isAuthenticated();

      if (isAuth == true) {
        next();
      } else {
        window.location.href = "/signin";
      }
    },
  },
  {
    path: "/profile/orders",
    name: "MyOrders",
    component: MyOrders,
    beforeEnter: async (to, from, next) => {
      var isAuth = await isAuthenticated();

      if (isAuth == true) {
        next();
      } else {
        window.location.href = "/signin";
      }
    },
  },
  {
    path: "/profile/cart",
    name: "MyCart",
    component: MyCart,
    beforeEnter: async (to, from, next) => {
      var isAuth = await isAuthenticated();

      if (isAuth == true) {
        next();
      } else {
        window.location.href = "/signin";
      }
    },
  },
  {
    path: "/profile/orders/:id",
    name: "OrderDetail",
    component: MyOrderDetail,
    beforeEnter: async (to, from, next) => {
      var isAuth = await isAuthenticated();

      if (isAuth == true) {
        next();
      } else {
        window.location.href = "/signin";
      }
    },
  },
  //not found page
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    //redirect to home page
    redirect: "/",
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
const store = createStore({
  state() {
    return {
      loading: true,
    };
  },
  mutations: {
    SET_LOADING(state, isLoading) {
      state.loading = isLoading;
    },
  },
});
router.beforeEach((to, from, next) => {
  store.state.loading = true;

  // Once the page is fully loaded, update the loading state to false
  next();
});
const app = createApp(App);
app.use(i18n);
app.use(router);
app.use(store); // Use the Vuex store
app.use(VueAwesomePaginate);
moment.tz.setDefault("Asia/Baghdad");
localStorage.getItem("lang") == "ku";
if (localStorage.getItem("lang") == "ar")
  moment.updateLocale("ar", {
    relativeTime: {
      future: "بعد %s",
      past: "منذ %s",
      s: "ثوانٍ",
      ss: "%d ثانية",
      m: "دقيقة",
      mm: "%d دقائق",
      h: "ساعة",
      hh: "%d ساعات",
      d: "يوم",
      dd: "%d أيام",
      M: "شهر",
      MM: "%d أشهر",
      y: "سنة",
      yy: "%d سنوات",
    },
  });
if (
  localStorage.getItem("lang") == "ku" ||
  localStorage.getItem("lang") == null ||
  localStorage.getItem("lang") == undefined ||
  localStorage.getItem("lang") == "" ||
  localStorage.getItem("lang") == "undefined" ||
  localStorage.getItem("lang") == "null"
)
  moment.updateLocale("ku", {
    relativeTime: {
      future: "له‌ %s",
      past: "%s پێش ئێستا",
      s: "چه‌ند چرکه‌یه‌ک",
      ss: "%d چرکه‌",
      m: "یه‌ک خوله‌ک",
      mm: "%d خوله‌ک",
      h: "یه‌ک کاتژمێر",
      hh: "%d کاتژمێر",
      d: "یه‌ک ڕۆژ",
      dd: "%d ڕۆژ",
      M: "یه‌ک مانگ",
      MM: "%d مانگ",
      y: "یه‌ک ساڵ",
      yy: "%d ساڵ",
    },
  });
//arabic

app.config.globalProperties.$moment = moment;

app.component("font-awesome-icon", FontAwesomeIcon);
app.use(createMetaManager());
app.mount("#app");
