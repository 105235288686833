<template>
  <!--
  <teleport to="head">
    <title>{{ item.i_name }}</title>
    <meta property="og:description" :content="item.i_description" />
    <meta property="og:site_name" content="KC STORE" />
    <meta
      property="og:image"
      :content="url + 'kcstore.online/public/storage/' + item.i_image"
    />
  </teleport>-->
  <Navbar />

  <!-- Contents -->
  <div class="mx-auto mt-8 md:mt-24">
    <!-- Main Content -->
    <main class="w-full flex flex-col lg:flex-row">
      <!-- Gallery -->

      <picture
        class="bg-red relative mx-auto w-75 sm:w-85 md:w-96 lg:w-330 mt-4 sm:mt-8 sm:mx-auto md:mx-auto lg:mx-0 items-center bg-orange sm:bg-transparent"
        v-if="!isLoading"
      >
        <img
          :src="url + 'kcstore.online/public/storage/' + item.i_image"
          alt="sneaker"
          class="rounded-xl xl:w-[70%] w-full xl:rounded-xl sm:m-1 md:m-auto pointer-events-none transition duration-300 lg:w-3/4 lg:pointer-events-auto lg:cursor-pointer lg:hover:shadow-xl"
          id="hero"
        />
      </picture>

      <div class="border-l-2 border-gray-400 rounded-lg my-8"></div>

      <!-- Text -->
      <section
        class="w-10/12 lg:w-full p-6 mx-auto lg:mx-0 mt-4 sm:mt-8 lg:mt-10 lg:pr-20 lg:py-10 2xl:pr-40"
        v-if="!isLoading"
      >
        <p class="text-gray-800 mb-4 font-bold text-2xl lg:text-3xl english">
          {{ item.i_name }}
        </p>
        <div class="flex items-center my-4 space-x-4">
          <ul class="flex flex-col my-5 space-y-2 list-none">
            <li
              class="inline-flex items-center space-x-2 text-gray-700 list-none"
            >
              <span class="w-1.5 h-1.5 rounded-full bg-primaryColor mx-1"></span
              ><span class="my_font mx-1 font-medium text-lg"
                >{{ $t("category") }} :
              </span>
              <span class="my_font font-medium text-lg">{{
                item.category.ca_name
              }}</span>
            </li>
            <li class="inline-flex items-center space-x-2 text-gray-700">
              <span
                class="w-1.5 h-1.5 rounded-full bg-primaryColor mx-1"
              ></span>
              <span class="my_font mx-1 font-medium text-lg"
                >{{ $t("sub_category") }} :
              </span>
              <span class="my_font font-medium text-lg">{{
                item.sub_category.sc_name
              }}</span>
            </li>
          </ul>
        </div>
        <p
          class="my_font text-gray-700 text-lg font-medium inline-block"
          v-if="item.i_is_show_mawa == 1 || item.i_mawa == 0"
        >
          {{ $t("number_item_in_store") }} :
          <span
            class="my_font inline-block h-fit py-0.5 px-2 font-bold bg-primaryColorDark  text-white rounded-lg text-sm"
            >{{ item.i_mawa == 0 ? $t("unavailable") : item.i_mawa }}</span
          >
        </p>

        <div class="items-center justify-between mb-6 flex-col sm:items-start">
          <div class="flex items-center gap-4">
            <h3
              class="my_font text-left tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-primaryColorDark to-primaryColorDark font-bold text-3xl inline-block"
            >
              {{
                formatNumber(
                  item.i_discount == 0 ? item.i_price : item.i_discount
                )
              }}
              {{ $t("iqd") }}
            </h3>
            <span
              class="my_font inline-block h-fit py-0.5 px-2 font-bold bg-primaryColorDark  text-white rounded-lg text-sm"
              v-if="item.i_discount != 0"
              >{{
                getDiscountPercentage(item.i_price, item.i_discount)
              }}
              %</span
            >
          </div>
          <p
            class="text-gray-600 w-fit line-through decoration-dark-grayish decoration-1 my-auto"
            v-if="item.i_discount != 0"
          >
            {{ formatNumber(item.i_price) }}
          </p>
        </div>

        <div
          class="flex gap-2 mb-16 flex-row lg:mb-0 w-full sm:w-4/5 md:w-3/5 lg:w-4/6"
        >
          <div
            class="w-full h-12 text-sm bg-blue-100 p-4 flex items-center justify-between rounded-lg font-bold relatives"
            v-if="item.i_mawa != 0"
          >
            <div id="minus" class="plus-minus">
              <div
                class="w-3 h-1 bg-primaryColorDark absolute"
                id="minus"
                @click="changeNumberItem('min')"
              ></div>
              <svg
                width="12"
                height="4"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <defs>
                  <path
                    d="M11.357 3.332A.641.641 0 0 0 12 2.69V.643A.641.641 0 0 0 11.357 0H.643A.641.641 0 0 0 0 .643v2.046c0 .357.287.643.643.643h10.714Z"
                    id="a"
                  />
                </defs>
                <use fill="#FF7E1B" fill-rule="nonzero" xlink:href="#a" />
              </svg>
            </div>
            <span id="amount" class="select-none">{{ counter }}</span>
            <div id="plus" class="plus-minus" @click="changeNumberItem('add')">
              <svg
                width="12"
                height="12"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                id="plus"
              >
                <defs>
                  <path
                    d="M12 7.023V4.977a.641.641 0 0 0-.643-.643h-3.69V.643A.641.641 0 0 0 7.022 0H4.977a.641.641 0 0 0-.643.643v3.69H.643A.641.641 0 0 0 0 4.978v2.046c0 .356.287.643.643.643h3.69v3.691c0 .356.288.643.644.643h2.046a.641.641 0 0 0 .643-.643v-3.69h3.691A.641.641 0 0 0 12 7.022Z"
                    id="b"
                  />
                </defs>
                <use
                  fill="#2B4168"
                  fill-rule="nonzero"
                  xlink:href="#b"
                  id="plus"
                />
              </svg>
            </div>
          </div>
          <button
            class="w-30 h-12 text-transparent bg-gradient-to-br from-primaryColor to-primaryColorDark py-2 flex items-center justify-center gap-4 text-xs rounded-lg font-bold text-white hover:brightness-125 transition select-none"
            id="add-cart"
            @click="updateUserFavorite(checkIfItemFavorite ? 'remove' : 'add')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              class="bi bi-heart"
              viewBox="0 0 16 16"
              v-if="!checkIfItemFavorite"
            >
              <path
                d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"
              ></path>
            </svg>
            <!--if item is added to favourite-->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              class="bi bi-heart-fill"
              viewBox="0 0 16 16"
              v-if="checkIfItemFavorite"
            >
              <path
                fill-rule="evenodd"
                d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
              />
            </svg>
          </button>
          <button
            class="my_font w-full h-12 text-transparent bg-gradient-to-br from-primaryColor to-primaryColorDark py-2 flex items-center justify-center gap-2 md:gap-4 lg:gap-1 xl:gap-4 text-base rounded-lg font-bold text-white hover:brightness-125 transition select-none"
            id="add-cart"
            @click="addToCart(item.i_id, counter)"
            v-if="item.i_mawa != 0"
          >
            <!-- <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20">
                            <path
                                d="M20.925 3.641H3.863L3.61.816A.896.896 0 0 0 2.717 0H.897a.896.896 0 1 0 0 1.792h1l1.031 11.483c.073.828.52 1.726 1.291 2.336C2.83 17.385 4.099 20 6.359 20c1.875 0 3.197-1.87 2.554-3.642h4.905c-.642 1.77.677 3.642 2.555 3.642a2.72 2.72 0 0 0 2.717-2.717 2.72 2.72 0 0 0-2.717-2.717H6.365c-.681 0-1.274-.41-1.53-1.009l14.321-.842a.896.896 0 0 0 .817-.677l1.821-7.283a.897.897 0 0 0-.87-1.114ZM6.358 18.208a.926.926 0 0 1 0-1.85.926.926 0 0 1 0 1.85Zm10.015 0a.926.926 0 0 1 0-1.85.926.926 0 0 1 0 1.85Zm2.021-7.243-13.8.81-.57-6.341h15.753l-1.383 5.53Z"
                                fill="hsl(223, 64%, 98%)" fill-rule="nonzero" />
                        </svg> -->
            <img
              src="/assets/image/add-to-cart.png"
              alt=""
              class="w-6 h-6"
              v-if="!checkIfItemAddedToCart(item.i_id)"
            />
            <font-awesome-icon
              v-if="checkIfItemAddedToCart(item.i_id)"
              :icon="['fas', 'check']"
              size="lg"
            />
            <span class="my_font hidden custom-div-show">
              {{
                $t(
                  checkIfItemAddedToCart(item.i_id)
                    ? "added_to_cart"
                    : "add_to_cart"
                )
              }}</span
            >
          </button>
        </div>
      </section>
    </main>

    <div class="mx-auto w-10/12 mt-6 mb-10" v-if="!isLoading">
      <p
        class="my_font font-bold text-6 mb-1 tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-primaryColor to-primaryColorDark"
      >
        {{ $t("about_item") }}
      </p>
      <div class="border-b-4 border-primaryColor w-44 rounded-lg"></div>

      <p
        class="my_font text-gray-800 mb-6 px-2 py-4 text-base sm:text-lg whitespace-pre-wrap"
      >
        {{
          lang == "en"
            ? item.i_desc_en
            : lang == "ar"
            ? item.i_desc_ar
            : item.i_desc
        }}
      </p>
    </div>

    <div class="mx-auto w-10/12 mt-6 mb-10" v-if="relateditems.length != 0">
      <p
        class="my_font font-bold text-6 mb-1 tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-primaryColor to-primaryColorDark"
      >
        {{ $t("related_items") }}
      </p>
      <div class="border-b-4 border-primaryColor w-44 rounded-lg"></div>
    </div>
    <!-- slider-->

    <section
      class="flex w-10/12 items-center mx-auto mt-20 mb-5 swiper myItemSwiper"
    >
      <div class="swiper-wrapper mb-16 mt-10">
        <!--item 1-->
        <div
          class="h-100 p-3 rounded-lg overflow-hidden shadow-md bg-white swiper-slide"
          v-for="(item1, index) in relateditems"
          :key="index"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-8 h-8 absolute top-2 text-primaryColorDark"
            v-if="!checkIfFavorite(item1.i_id)"
            @click="updateUserFavoriteRelated('add', item1.i_id)"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
            />
          </svg>

          <div v-else @click="updateUserFavoriteRelated('remove', item1.i_id)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-8 h-8 absolute top-2 text-primaryColorDark"
            >
              <path
                d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z"
              />
            </svg>
          </div>
          <a :href="'/products/' + item1.i_id">
            <div class="flex w-64 h-56 md:w-72 md:h-68 mb-8 md:mb-0 mx-auto">
              <img
                class="object-cover rounded-lg items-center"
                :src="url + 'kcstore.online/public/storage/' + item1.i_image"
                alt=" Image"
              />
            </div>
            <h3 class="my_font text-xl text-gray-800 text-left pt-2">
              {{ item1.i_name }}
            </h3>
            <div class="my_font text-3.5 font-sm text-gray-800 line-clamp-2">
              {{
                lang == "en"
                  ? item1.i_desc_en
                  : lang == "ar"
                  ? item1.i_desc_ar
                  : item1.i_desc
              }}
            </div>
          </a>
          <div class="flex justify-between pt-6 items-start">
            <div class="">
              <span class="my_font text-lg font-medium text-gray-800 text-left">
                {{
                  formatNumber(
                    item1.i_discount == 0 ? item1.i_price : item1.i_discount
                  )
                }}
                {{ $t("iqd") }}
              </span>
              <br />
              <span
                class="my_font line-through text-base font-medium text-gray-600 text-left"
                v-if="item1.i_discount != 0"
              >
                {{ formatNumber(item1.i_price) }}</span
              >
              <span
                class="my_font text-xs font-medium text-gray-600 text-left"
                v-else
              >
                <!-- white space-->
                &nbsp;
              </span>
            </div>
            <p
              class="my_font text-gray-700 text-lg font-medium inline-block"
              v-if="item1.i_mawa == 0"
            >
              <span
                class="my_font inline-block h-fit py-0.5 px-2 font-bold bg-primaryColorDark  text-white rounded-lg text-sm"
                >{{
                  item1.i_mawa == 0 ? $t("unavailable") : item1.i_mawa
                }}</span
              >
            </p>
            <button @click="addToCart(item1.i_id, 1)" v-else>
              <img
                src="/assets/icon/cart-plus.svg"
                class="w-5"
                alt=""
                v-if="!checkIfItemAddedToCart(item1.i_id)"
              />
              <font-awesome-icon
                v-if="checkIfItemAddedToCart(item1.i_id)"
                :icon="['fas', 'check']"
                size="xl"
                class="text-primaryColor"
              />
            </button>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </section>
  </div>

  <LoginModal :show="isLoginShow" @close="closeModalLogin" />

  <Footer />
</template>

<script>
import Navbar from "../components/nav-bar.vue";
import Footer from "../components/footer-app.vue";
import LoginModal from "../components/login-modal.vue";

import Swiper from "swiper";

import "swiper/swiper-bundle.min.css";
import { SERVER_URL } from "../../config.js";
import axios from "axios";

export default {
  name: "App",

  components: {
    Navbar,
    Footer,
    LoginModal,
  },
  data() {
    return {
      url: SERVER_URL,
      item: {},
      relateditems: [],
      isLoading: true,
      isLoadingRelated: true,
      isLoginShow: false,
      user: {},
      counter: 1,
      lang: localStorage.getItem("lang"),
    };
  },
  created() {
    this.getItem();
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  computed: {
    checkIfItemFavorite() {
      if (this.user == null) {
        return false;
      }
      if (
        this.user.u_favorite == null ||
        this.user.u_favorite == "" ||
        this.user.u_favorite == ","
      ) {
        return false;
      } else {
        var favorites = this.user.u_favorite;
        //split the string into array by comma
        var favoritesArray = favorites.split(",");

        //check if the id is in the array or not
        if (favoritesArray.includes(this.item.i_id.toString())) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  mounted() {
    this.initSwipers();
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    initSwipers() {
      var itemSwiper = new Swiper(".myItemSwiper", {
        //in mobile view 2 slides
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          //in tab view 3 slides
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          //in desktop view 4 slides
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1400: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        },
        grid: {
          rows: 1,
        },
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        keyboard: true,
        speed: 1000,
      });

      setInterval(() => {
        itemSwiper.slideNext();
      }, 5000);
    },
    //function to get price and discount price return percentage
    getDiscountPercentage(price, discountPrice) {
      let percentage = ((price - discountPrice) / price) * 100;
      return Math.round(percentage);
    },
    getItem() {
      axios
        .post(SERVER_URL + "api/item/show/" + this.$route.params.id, {
          lang: localStorage.getItem("lang"),
        })
        .then((response) => {
          // this.items = response.data;
          this.item = response.data;
          this.isLoading = false;

          this.getRelatedItems();
        })
        .catch((error) => {
          console.log(error);

          this.getRelatedItems();
        });
    },
    getRelatedItems() {
      axios
        .post(SERVER_URL + "api/item/related", {
          item_id: this.$route.params.id,
          lang: localStorage.getItem("lang"),
        })
        .then((response) => {
          // this.items = response.data;
          this.relateditems = response.data;
          this.isLoadingRelated = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoadingRelated = false;
        });
    },

    updateUserFavorite(action) {
      if (this.user == null) {
        this.isLoginShow = true;
        return false;
      }

      if (action == "add") {
        if (this.user.u_favorite == null || this.user.u_favorite == "") {
          this.user.u_favorite = "," + this.item.i_id;
        } else
          this.user.u_favorite = this.user.u_favorite + "," + this.item.i_id;
      } else {
        //remove the item from the favorite
        var favorites = this.user.u_favorite;
        //split the string into array by comma
        var favoritesArray = favorites.split(",");

        //check if the id is in the array or not
        if (favoritesArray.includes(this.item.i_id.toString())) {
          //remove the item from the array
          var index = favoritesArray.indexOf(this.item.i_id.toString());
          if (index > -1) {
            favoritesArray.splice(index, 1);
          }
          //convert the array to string
          var favoritesString = favoritesArray.toString();
          //remove the first comma
          favoritesString = favoritesString.replace(/^,/, "");
          this.user.u_favorite = favoritesString;
        }
      }
      axios
        .post(SERVER_URL + "api/item/add-favorite", {
          i_id: this.item.i_id,
          action: action,
        })
        .then((response) => {
          //update the local storage
          localStorage.setItem("user", JSON.stringify(response.data));
          this.$store.state.user = JSON.parse(localStorage.getItem("user"));
          this.user = JSON.parse(localStorage.getItem("user"));
        })
        .catch((error) => {
          console.log(error);
        });
    },

    closeModalLogin() {
      this.isLoginShow = false;
    },
    checkIfFavorite(id) {
      //loop inside user favorite split by comma
      //if item id exist return true
      if (this.user == null) return false;
      if (this.user.u_favorite == null || this.user.u_favorite == "")
        return false;
      var favoritesTemp = this.user.u_favorite.split(",");
      for (var i = 0; i < favoritesTemp.length; i++) {
        if (favoritesTemp[i] == id) {
          return true;
        }
      }

      return false;
    },
    updateUserFavoriteRelated(action, itemID) {
      if (this.user == null) {
        this.isLoginShow = true;
        return false;
      }

      if (action == "add") {
        if (this.user.u_favorite == null || this.user.u_favorite == "") {
          this.user.u_favorite = "," + itemID;
        } else this.user.u_favorite = this.user.u_favorite + "," + itemID;
      } else {
        //remove the item from the favorite
        var favorites = this.user.u_favorite;
        //split the string into array by comma
        var favoritesArray = favorites.split(",");

        //check if the id is in the array or not
        if (favoritesArray.includes(itemID.toString())) {
          //remove the item from the array
          var index = favoritesArray.indexOf(itemID.toString());
          if (index > -1) {
            favoritesArray.splice(index, 1);
          }
          //convert the array to string
          var favoritesString = favoritesArray.toString();
          //remove the first comma
          favoritesString = favoritesString.replace(/^,/, "");
          this.user.u_favorite = favoritesString;
        }
      }
      axios
        .post(SERVER_URL + "api/item/add-favorite", {
          i_id: itemID,
          action: action,
        })
        .then((response) => {
          //update the local storage
          localStorage.setItem("user", JSON.stringify(response.data));
          this.$store.state.user = JSON.parse(localStorage.getItem("user"));
          this.user = JSON.parse(localStorage.getItem("user"));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addToCart(id, count) {
      if (this.user == null) {
        this.isLoginShow = true;
        return false;
      }
      //[{i_id:1,count:2},{i_id:2,count:1}] the format of the cartn as string

      //check if u_saba is null or empty
      if (this.user.u_sabata == null || this.user.u_sabata == "") {
        var arraySabata = [];
        var item2 = {
          i_id: id,
          count: count,
        };
        arraySabata.push(item2);
        const strList = JSON.stringify(arraySabata);
        this.user.u_sabata = strList;
      }
      //split the string into array by comma
      var cartTemp = this.user.u_sabata.toString();
      var cartArray = JSON.parse(cartTemp);

      var isItemExist = false;
      for (var j = 0; j < cartArray.length; j++) {
        if (cartArray[j].i_id == id) {
          isItemExist = true;
        }
      }
      if (!isItemExist) {
        var item1 = {
          i_id: id,
          count: count,
        };
        cartArray.push(item1);
      }
      //convert the array to string
      var cartString = JSON.stringify(cartArray);

      this.user.u_sabata = cartString;

      axios
        .post(SERVER_URL + "api/item/add-sabata", {
          items_array_string: cartString,
        })
        .then((response) => {
          //update the local storage
          localStorage.setItem("user", JSON.stringify(response.data));
          this.$store.state.user = JSON.parse(localStorage.getItem("user"));
          this.user = JSON.parse(localStorage.getItem("user"));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkIfItemAddedToCart(id) {
      if (this.user == null) return false;
      if (this.user.u_sabata == null || this.user.u_sabata == "") return false;
      //[{i_id:1,count:2},{i_id:2,count:1}] the format of the cartn as string
      var cartTemp = this.user.u_sabata.toString();
      var cartArray = JSON.parse(cartTemp);

      for (var i = 0; i < cartArray.length; i++) {
        if (cartArray[i].i_id == id) {
          return true;
        }
      }

      return false;
    },
    changeNumberItem(action) {
      if (action == "add") {
        if (this.item.i_is_show_mawa == 1) {
          if (this.item.i_mawa == this.counter) {
            alert(this.$t("you_cant_order_more_than") + " " + this.item.i_mawa);
            return;
          }
        }
        this.counter++;
      } else {
        if (this.counter > 1) {
          this.counter--;
        }
      }
    },
  },
};
</script>
<style scoped>
.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* Number of lines to display */
  line-clamp: 2; /* Number of lines to display */
}
@media (min-width: 640px) and (max-width: 768px) {
  .custom-div-show {
    display: block !important;
  }
}
</style>
